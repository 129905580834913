import axios from "axios";
import { useState } from "react";

function CreateUser(){

    let [formData, setFormData] = useState({
        uLogin:"",
        uPass:"",
        cPass:"",
        uType:"Admin",
        dStatus:"V"
    });

    let [errors, setErrors] = useState({
        uLogin:"",
        uPass:"",
        cPass:""
    });

    function handleChange(e){
        let {name , value} = e.target;

        setFormData({
            ...formData,
            [name] : value
        });

        setErrors({
            ...errors,
            [name]: ""
        })
    }

    function Validate(){
        let newErrors = {};
        let errorMessages = [];

        if(!formData.uLogin){
             newErrors.uLogin = "Username is required";
            errorMessages.push("Username is required");
            }
        if(!formData.uPass){
             newErrors.uPass = "Password is required";
             errorMessages.push("Password is required");
            }
        if(formData.uPass.length <= 5){
            newErrors.uPass = "Password must be 6 character!";
            errorMessages.push("Password must be 6 character!");
            }
        if(formData.uPass != formData.cPass){
             newErrors.cPass = "Password does not match!";
             errorMessages.push("Password does not match!");
            }

        setErrors(newErrors);

        if(errorMessages.length > 0){
            alert(errorMessages.join("\n"));
        }
        
        return Object.keys(newErrors).length === 0;
    }

    function submit(){
        if(Validate()){

            let dataToSend={
                uLogin:formData.uLogin,
                uPass:formData.uPass,
                uType:"Admin",
                dStatus:"V"
            }

            axios.post(process.env.REACT_APP_BASE_URL+"/Login/CreateUser",dataToSend, {
                headers:{
                    Authorization: `Bearer ${window.token}`
                }
            }).then(reply=>{
                if(reply.status === 200){
                    if(reply.data.status === "OK"){
                        alert("User created successfully!");
                    }
                    else{
                        alert(reply.data.message);
                    }
                }
                else{
                    alert(reply.status);
                }
            })
            
        }
    }

    return(
        <>
        <div className="container-fluid row justify-content-center" style={{marginTop:"100px"}}>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 custom-div">
                <div className="row mt-2">
                    <div className="col">
                        <label>Username</label>
                        <input 
                        className={`form-control form-control-sm ${errors.uLogin? "is-invalid" : ""}`}
                        value={formData.uLogin}
                        onChange={handleChange}
                        name="uLogin" />
                        <div className="error">{errors.uLogin}</div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <label>Password</label>
                        <input className={`form-control form-control-sm ${errors.uPass? "is-invalid" : ""}`} type="password" value={formData.uPass} name="uPass"  onChange={handleChange}/>
                        <div className="error">{errors.uPass}</div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <label>Confirm Password</label>
                        <input className={`form-control form-control-sm ${errors.cPass? "is-invalid" : ""}`} value={formData.cPass} name="cPass" onChange={handleChange}/>
                        <div className="error">{errors.cPass}</div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col">
                        <button className="custom-button" onClick={submit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default CreateUser;