import { useEffect, useState } from "react";
import ProductPhoto from "../createOrder/productPhoto";
import API_AUTH from "../../../api_auth";
import { addInBasket, addInTotal } from "../../../../action";
import { useDispatch } from "react-redux";


function ProductList({cid}){ 
  
    let dispatch = useDispatch();

    let [products, setProducts] = useState([]);
  
   function outOfStock(){
      
     API_AUTH().get("/Stock/GetAllStock").then(reply=>{
        if(reply.status == 200){
          if(reply.data.status == "OK"){
            for (let index = 0; index < reply.data.data.length; index++) {
              
              if(reply.data.data[index].qtyInStock == 0){
                // document.addEventListener("DOMContentLoaded", function(event){

                  document.getElementById("idQty"+reply.data.data[index].pid).disabled = true;
                  document.getElementById("idOut"+reply.data.data[index].pid).style.display = "block";
                  document.getElementById("btn"+reply.data.data[index].pid).disabled = true;
                  document.getElementById("idAdd"+reply.data.data[index].pid).disabled = true;
                  document.getElementById("idMinus"+reply.data.data[index].pid).disabled = true;
                // })
              }
            }
          }
        }
      })
    }

    async function addQty(pid){

            let qty = parseInt(document.getElementById("idQty"+pid).value);
            qty = qty + 1;
            document.getElementById("idQty"+pid).value = qty;
          
      }

      function minusQty(i){
        let qty = parseInt(document.getElementById("idQty"+i).value);
        if(qty < 2) return;
        qty = qty - 1;
        document.getElementById("idQty"+i).value = qty;
        // console.log(qty);
        
      }


      // Get Product By Category Id
  function getProductByCid() {
    
    API_AUTH().get("/Products/ProductByCategoryId/" + cid)
      .then((reply) => {
        if (reply.status === 200) {
          if (reply.data.status === "OK") {
            setProducts(reply.data.data);
            // setInStock(true);
          }
          else console.log(reply.data.message);
          
        } else {
          console.log(reply.status);
        }
      });
  }

  function addToCart(pid,price, qty){

    // console.log(pid,":",price*qty,":",qty);
    dispatch(addInBasket(pid, price, qty));  
    dispatch(addInTotal());
    
    

  }

  useEffect(()=>{
    getProductByCid();
    outOfStock();
  },[]);

  return(
    <>
         {products.map((e,i) => (
                <div
                 id={i}
                  className="card mt-3"
                  style={{ width: "18rem", height:"25rem"}}
                >
                  <div className="row" style={{height:"12rem"}}>
                   
                  <ProductPhoto pid={e.pid} />

                  </div>
                  <div style={{bottom:"0", alignItems:"end"}} className="card-body align-items-end">
                    <label id={"idOut"+e.pid} style={{display:"none", color:"red", fontSize:"20px", fontWeight:"bold"}}>Out Of Stock</label>
                    <h5 className="card-title">{e.productName}</h5>
                    <p className="card-text">
                      Vehicle Type: {e.vehicleType}
                    </p>
                    <p className="card-text">
                      Price: {e.price}
                    </p>
                    <div className="row">
                      <div className="col d-flex">

                        <button id={"idAdd"+e.pid} className=" " style={{ border:"none", backgroundColor:"white", color:"black", margin:"5px",fontWeight:"bold" }} onClick={()=>minusQty(e.pid)}>-</button>
                        <input id={"idQty"+e.pid} className="" size={1} style={{ borderRadius:"5px",padding:"5px"}} value={1}/>
                        <button id={"idMinus"+e.pid} className=" " style={{ border:"none", backgroundColor:"white", color:"black", margin:"5px",fontWeight:"bold" }} onClick={()=>addQty(e.pid)} >+</button>
                      
                      </div>

                      <div className="col">

                        <button id={"btn"+e.pid} onClick={()=>addToCart(e.pid, e.price, parseInt(document.getElementById("idQty"+e.pid).value))} className="btn btn-primary">+Add</button>

                      </div>
                    </div>
                  </div>
                </div>
              ))}
    </>
  )
}

export default ProductList;