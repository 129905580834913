import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import avatar from './images/avatar.png';

import {
  CNavbarToggler,
  CCollapse,
  CNavbarNav,
  CNavItem,
  CNavLink,
} from "@coreui/react";

import API_AUTH from "../api_auth";

function DealerHeader() {
  const [isOpen, setIsOpen] = useState(false);
  let [dealerName, setDealerName] = useState("there");

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  function closeNav(){
    setIsOpen(false);
  }

  let dc = window.uName;
  // console.log(dc);
  
  async function dealerDetails() {
   
      API_AUTH().get("/Dealers/GetDealer/"+dc).then((reply) => {
        if (reply.status === 200) {
          if (reply.data.status === "OK") {            
            if (reply.data.data) {
              setDealerName(reply.data.data.contactPerson);
              
            } else {
             

           
            }
          } else {
            console.log(reply.data.status);
          }
        } else {
          console.log(reply);
        }
      });
    }
  
 useEffect(() => {

      dealerDetails();

  }, []);


    return(
      <>
      <CNavbarToggler
            aria-label="Toggle navigation"
            aria-expanded={isOpen}
            onClick={toggleNavbar}
            />
      <CCollapse className="navbar-collapse" visible={isOpen}>
        <CNavbarNav>
              <CNavItem>
              <CNavLink><Link className="nav-link" to="/createOrder" onClick={closeNav}>Create Order</Link></CNavLink>
              </CNavItem>

              <CNavItem>
              <CNavLink><Link className="nav-link" to="/returnItem" onClick={closeNav}>Item Return</Link></CNavLink>
              </CNavItem>

              <CNavItem>
              <CNavLink><Link className="nav-link" to="/viewOrders" onClick={closeNav}>My Orders</Link></CNavLink>
              </CNavItem>
             
              <CNavItem>
              <CNavLink><Link className="nav-link" to="/viewDealerTransactions" onClick={closeNav}>My Transactions</Link></CNavLink>
              </CNavItem>
             
              <CNavItem>
              <CNavLink><Link className="nav-link" to="/newTicket" onClick={closeNav}>Query</Link></CNavLink>
              </CNavItem>
             
            </CNavbarNav>

            
            <div className="ms-auto d-flex">

            <Link to="/dealerDashboard"><button className="btn border custom-btn">Dashboard</button></Link>
            <label style={{marginTop:"6px", marginLeft:"10px"}}>Hi, {dealerName}!</label>
            <NavDropdown align={{ sm:'end', md:'start', lg:'start', xl:'start', xxl:'start' }} title={<img src={avatar} alt="avatar" style={{width:"30px"}}></img>} className="btn">

            <LinkContainer to="/changeDealerPassword">
              <NavDropdown.Item>Change Password</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/logout">
              <NavDropdown.Item>Logout</NavDropdown.Item>
            </LinkContainer>
            
          </NavDropdown>
            
            </div>
            </CCollapse>

            </>
    
    )
}

export default DealerHeader;