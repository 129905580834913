import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CartRow } from "./cartRow";
import { addInBasket, addInTotal, clearBasket } from "../../../../action";
import axios from "axios";
import closeImg from "../../../../images/closeImg.png";

function Cart(params) {
  let basket = useSelector((state) => state.basket);
  let totalAmount = useSelector((state) => state.total);
  let dispatch = useDispatch();

  for (let index = 0; 0 > index; index++) {
    console.log(index);
  }

  async function createOrder() {
    let dataToSend = {
      dealerCode: window.uName,
      amount: totalAmount,
      comment: document.getElementById("idComment").value,
      orderDetails: basket,
    };
    
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "/PurchaseOrder/CreateOrder",
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${window.token}`,
          },
        }
      )
      .then((reply) => {
        if (reply.status == 200) {
          if (reply.data.status == "OK") {

            alert("Order Placed!");
            
            dispatch(clearBasket());
            dispatch(addInTotal());

          } else {
            alert(reply.data.message);
          }
        }
      });
  }

  function closeCart() {
    params.toClose(false);
  }

  return (
    <>
      <div
        id="mainDiv"
        className="popup-div container-fluid"
        style={{
          zIndex: "50",
          overflowY: "auto",
          paddingTop: "100px",
          paddingLeft: "20px",
        }}
      >
        <div className="row" style={{ marginBottom: "50px" }}></div>

        <div className="row">
          <div
            className="col-xl-8 col-lg-8 col-md-8 col-sm-10 custom-div"
            style={{
              overflow: "auto",
            }}
          >
            

            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                margin: "10px",
              }}
            >
              <img
                src={closeImg}
                onClick={closeCart}
                style={{ cursor: "pointer", width: "38px" }}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12" >
              <div className=" card p-2" style={{justifyContent:"flex-end", display:"flex"}}>
                <label>Total Items: {basket.length}</label>
                <br />
                <label>Total Amount: {totalAmount}</label>
                <br />
                <input
                  id="idComment"
                  className="form-control mb-2"
                  placeholder="Comment..."
                ></input>
                <button
                  className="btn btn-warning"
                  disabled={basket.length > 0 ? false : true}
                  onClick={createOrder}
                >
                  Place Order
                </button>
              </div>
            </div>



            {basket.length > 0 ? (
              basket.map((e, i) => (
                <CartRow pid={e.pId} price={e.price} quantity={e.qty} />
              ))
            ) : (
              <h3>No Item in cart</h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
}



export default Cart;
