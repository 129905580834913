import { useEffect, useState } from "react";
import API_AUTH from "../../../api_auth";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ProductList from "./productList";
import plusImg from "../../images/plus.png";
import minusImg from "../../images/minus.png";
import Cart from "./cart";


function CreateOrder({ commonData }) {
  let nav = useNavigate();
  let [Category, setCategory] = useState([]);
  let [products, setProducts] = useState([]);
  let [selectedCategoryId, setSelectedCategoryId] = useState(null);

  let [isOpen, setIsOpen] = useState(false);
  let [showCart, setShowCart] = useState(false);
  let [openCategories, setOpenCategories] = useState({});

  // Get Category
  async function getCategory() {
   await API_AUTH()
      .get("/Categories/AllCategories")
      .then((reply) => {
        if (reply.status === 200) {
          if (reply.data.status) {
            setCategory(reply.data.data);
          } else {
            console.log("error:" + reply.data.message);
          }
        } else {
          console.log(reply);
        }
      });
  }

  const handleCategoryClick = (cid) => {
    setSelectedCategoryId(cid);
    setIsOpen(!isOpen);
  
  };

  function closeCart(value){
    setShowCart(value);

  }

  useEffect(() => {
    if (commonData.isLogin == "N") {
      nav("/");
    } else {
      getCategory();
    }
  }, []);

  return (
    <>
      <div
        style={{
          overflow: "auto",
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          backgroundColor: "white",
        }}
        className="justify-content-center row"
      >
        <div
          style={{ marginTop: "100px" }}
          className="col-xl-10 col-lg-10 col-md-10 col-sm-12"
        >
          <div className="row">
            <div className="col">
              {/* cart */}
              <button className="btn btn-primary" onClick={()=>setShowCart(!showCart)}>Cart</button>
              {showCart && <Cart toClose={closeCart} />}
            </div>
          </div>
          <div className="row">
            {Category.map((e, i) => (
              <div className="">
                <div className="row category-custom-div mt-2 p-1 d-flex">
                  <div key={e.cid} className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-11 d-flex flex-row">
                    {e.categoryName}
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1" style={{justifyContent:"flex-end", display:"flex"}}>
                    <img
                      id={i}
                      src={isOpen ? minusImg : plusImg}
                      onClick={() => handleCategoryClick(e.cid)}
                      className=""
                      style={{ width: "30px" }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#C" + i}
                    />
                  </div>
                  
                </div>
        
                <div className="collapse" id={"C" + i}>
                  {selectedCategoryId && (
                    <div className="row m-2  product-custom-div border">
                      <ProductList cid={e.cid}/>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

let connectToStore = (state) => ({ commonData: state });
export default connect(connectToStore)(CreateOrder);
