export default function Pagination({totalOrders, orderPerPage, setCurrentPage}){

    let pages = [];

    for (let index = 1; index <= Math.ceil(totalOrders/orderPerPage); index++) {
        pages.push(index);
        
    }

    return(
        <>
        <div className="d-flex justify-content-center">
        {pages.map((e)=>(
            <button className="btn btn-secondary m-1" onClick={()=>setCurrentPage(e)}>{e}</button>
        ))}
        </div>
        </>
    )
}