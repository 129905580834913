import axios from "axios";
import { useState } from "react";

function ChangeDealerPassword(){

    let [formData, setFormData] = useState({
        uLogin:"",
        uPass:"",
        cPass:"",
        uType:"Admin",
        dStatus:"E"
    });

    let [errors, setErrors] = useState({
        uPass:"",
        cPass:""
    });

    function handleChange(e){
        let {name , value} = e.target;

        setFormData({
            ...formData,
            [name] : value
        });

        setErrors({
            ...errors,
            [name]: ""
        })
    }

    function Validate(){
        let newErrors = {};
        let errorMessages = [];

        if(!formData.uPass) {
            newErrors.uPass = "Password is required!";
            errorMessages.push("Password is required!");
        }
        if(formData.uPass.length <= 5){
            newErrors.uPass = "Password must be 6 character!";
            errorMessages.push("Password must be 6 character!");
        }
        if(formData.uPass != formData.cPass)
            {
                 newErrors.cPass = "Password does not match!";
                 errorMessages.push("Password does not match!");
                 
                }

        setErrors(newErrors);

        if(errorMessages.length > 0){
            alert(errorMessages.join("\n"));
        }

        return Object.keys(newErrors).length === 0;
    }

    function submit(){ 
        
        if(Validate()){

            let dataToSend={
                uLogin:window.uName,
                uPass:formData.uPass,
                uType:"Admin",
                dStatus:"E"
            }
            
            axios.post(process.env.REACT_APP_BASE_URL+"/Login/changePassword", dataToSend, {
                headers:{
                    Authorization: `Bearer ${window.token}`
                }
            }).then(reply=>{
                if(reply.status === 200){
                    if(reply.data.status === "OK"){
                        alert(reply.data.message);
                    }
                    else{
                        alert(reply.data.message);
                    }
                }
                else{
                    alert(reply.status);
                }
            })
            
        }
    }
    return(
        <>
        <div className="container-fluid row justify-content-center" style={{marginTop:"100px"}}>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 custom-div ms-4">
               
                <div className="row mt-2">
                    <div className="col">
                        <label>Password</label>
                        <input className={`form-control form-control-sm ${errors.uPass}`} onChange={handleChange} value={formData.uPass} name="uPass" />
                        <div className="error">{errors.uPass}</div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <label>Confirm Password</label>
                        <input className={`form-control form-control-sm ${errors.cPass}`} onChange={handleChange} value={formData.cPass} name="cPass" />
                        <div className="error">{errors.cPass}</div>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col">
                        <button className="custom-button" onClick={submit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default ChangeDealerPassword;