import './App.css';
import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from './Components/login';
import Header from './Components/Header';
import AddDealer from './Components/Admin/Dealer/AddDealer';
import AddCategory from './Components/Admin/Category/AddCategory';
import Product from './Components/Admin/Product/addProduct';
import AddStockDetails from './Components/Admin/Stock/addStock';
import AdminDashboard from './Components/Admin/adminDashboard';
import Logout from './Components/logout';
import DealerDashboard from './Components/Dealer/dealerDashboard';
import CreateOrder from './Components/Dealer/Order/createOrder/createOrder';
import ViewOrders from './Components/Dealer/Order/All Orders/viewOrders';
import SavePaymentDetails from './Components/Admin/Transaction/savePaymentDetails';
import ViewDealerTransactions from './Components/Dealer/Transactions/viewDealerTransactions';
import NewTicket from './Components/Dealer/query/newTicket';
import CreateUser from './Components/Admin/createUser';
import ChangeAdminPassword from './Components/Admin/changeAdminPassword';
import ChangeDealerPassword from './Components/Dealer/changeDealerPassword';
import ReplyToQuery from './Components/Admin/replyTickets/replyToQuery';
import FinalBill from './Components/Dealer/bill/finalBill';
import ReturnItem from './Components/Dealer/ReturnItem/returnItem';
import App_Del_ReturnItem from './Components/Admin/ReturnItem/app_del_request';

window.tokon = "";
window.utype = "";
window.uName = "";
window.pcode = "";

function App() {
  return (
    <Fragment>
 
    <Header/>
    <Routes>
      <Route path='/' element={<Login/>} />
      <Route path='/logout' element={<Logout/>} />

      {/* Admin */}
      <Route path='/createUser' element={<CreateUser/>} />
      <Route path='/changeAdminPassword' element={<ChangeAdminPassword/>} />
      <Route path='/dealer' element={<AddDealer/>} />
      <Route path='/category' element={<AddCategory/>} />
      <Route path='/product' element={<Product/>} />
      <Route path='/stock' element={<AddStockDetails/>} />
      <Route path='/adminDashboard' element={<AdminDashboard/>} />
      <Route path='/savePaymentDetails' element={<SavePaymentDetails/>} />
      <Route path='/replyToQuery' element={<ReplyToQuery/>} />
      <Route path='/finalBill' element={<FinalBill/>} />
      <Route path='/returnItemAdmin' element={<App_Del_ReturnItem/>} />
      
      {/* Dealer */}
      <Route path='/changeDealerPassword' element={<ChangeDealerPassword/>} />
      <Route path='/createOrder' element={<CreateOrder/>} />
      <Route path='/dealerDashboard' element={<DealerDashboard/>} /> 
      <Route path='/viewOrders' element={<ViewOrders/>} />     
      <Route path='/viewDealerTransactions' element={<ViewDealerTransactions/>} />     
      <Route path='/newTicket' element={<NewTicket/>} />
      <Route path='/returnItem' element={<ReturnItem/>} />

    </Routes>
      
    </Fragment>
  );
}

export default App;
