import { useEffect, useState } from "react";
import API_AUTH from "../../../api_auth";
import logo from '../../images/logoBG31.png';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export default function ProductPhoto(params)
{
    let [photos,setPhotos]=useState([logo]);
    function getPhotos()
    {
        API_AUTH().get("/ProductPhoto/GetPhotoByProductId/"+params.pid).then(reply=>{
            if(reply.status == 200){
                if(reply.data.status == "OK"){
                    if(reply.data.data && reply.data.data.length > 0){
                        setPhotos(reply.data.data);
                    }
                    else{
                        setPhotos([logo]);
                    }
                }
                else{
                    
                }
            }
            else{
                console.log(reply.status);
            }
        });
    }
    useEffect(()=>{
        getPhotos();
    },[]);
    return(
        <div>
            <Slide>
            {photos.map((e,i)=>
                <img src={e.photo? "data:image/jpeg;base64,"+e.photo : e} 
                style={{width:"13rem",height:"13rem",padding:"5px",
                     display:"block",
                     marginLeft:"auto",
                     marginRight:"auto",
                    }} />

            )}
            </Slide>
        </div> 

    );
}