export default function OrderCard(params){

    return(
        <div className="card m-1 custom-card" style={{paddingLeft:"10px"}}>
            <div className="row">
            <div className="col">
                <label style={{fontSize:"15px"}}>Order ID</label><br/>
                <label style={{fontWeight:"bold", fontSize:"17px"}}>{params.orderId}</label>
            </div>
            <div className="col">
                <label style={{fontSize:"15px"}}>Order Date</label><br/>
                <label style={{fontWeight:"bold", fontSize:"15px"}}>{params.orderDate.split('T')[0]}</label>
            </div>
            <div className="col">
                <label style={{fontSize:"15px"}}>Amount</label><br/>
                <label style={{fontWeight:"bold", fontSize:"15px"}}>{params.amount}</label>
            </div>
            </div>
        </div>
    )
}