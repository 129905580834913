 
import axios from "axios";
import { useEffect, useState } from "react";

function ShowStock() 
{
      
const [stockData, setStockData]=useState({data:[]});

function getStock()
{
    let AUTH = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: { Authorization: `Bearer ${window.token}` },
    });  

    AUTH.get("/Stock/GetAllStock").then(reply=>{
        if(reply.status==200)
        {
            setStockData(reply.data);
        }
    });
}


useEffect(()=>{
  getStock();
},[]);


    return (
        <>
            <div className="container">
                <div className="col ">
                    <div className="row mt-5 align-items-center justify-content-center">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <table className="table table-bordered table-striped">
                                <thead className="bg-dark text-light text-center">
                                    <th> Product Code </th>
                                    <th> Product Name </th>
                                    <th> Vehicle Type </th>
                                    <th> Quantity </th>
                                </thead>
                                <tbody>
                                    {stockData.data.map((e)=>
                                    <tr>
                                        <td> {e.productCode} </td>
                                        <td> {e.productName} </td>
                                        <td> {e.vehicleType} </td>
                                        <td> {e.qtyInStock} </td>
                                    </tr>
                                    )}        
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ShowStock;
