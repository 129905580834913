import React, { useEffect, useState } from "react";
import ShowStock from "./stocktable";
import axios from "axios";
import { connect } from "react-redux";

function AddStockDetails({ commonData }) {
  const [data, setDetails] = useState({
    category: "",
    productCode: "",
    qtyUpdate: "",
    comment: "",
  });



/////////////////////////// Validation //////////////////////////// 
let [errors, setErrors] = useState({});
const validate = () => {

let errors = {};
let errorMessage = [];

if (!data.category.trim()) {
  errors.category = "Category is required";
  errorMessage.push(errors.category);
}

if (!data.productCode.trim()) {
  errors.productCode = "Product code is required";
  errorMessage.push(errors.productCode);
}

if (!data.qtyUpdate || !Number.isInteger(Number(data.qtyUpdate)) || Number(data.qtyUpdate) <= 0) {
  errors.qtyUpdate = "Enter valid quantity";
  errorMessage.push(errors.qtyUpdate);
}

setErrors(errors);

if(errorMessage.length > 0){
alert(errorMessage.join("\n"));
}
return Object.keys(errors).length == 0;
};

/////////////////////////////////////////////////////////////////////////




/////////////////////////// Select Categories ///////////////////////////
let [category, setCategory] = useState([]);

function getCategory() {
  let AUTH = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Authorization: `Bearer ${window.token}` },
  });

  AUTH.get("/Categories/AllCategories").then((reply) => {
  // console.log(reply.data.data);
    setCategory(reply.data.data);
  }).catch(err => {
  // console.log("err form get State: ",err);
    if (err.response.status == 401) {
      // console.log("Unauthorize");
    }
  })
}
////////////////////////////////////////////////////////////////////////

//////////////////////// Get Selected Category //////////////////////////
function getSelectCategory(e) {
  let category = e.target.value;
  const { name, value } = e.target;
  getProduct(category);
  setDetails({
    ...data,
    [name]: value,
  });

  setErrors({
    ...errors,
    [name]: "",
  });
}
////////////////////////////////////////////////////////////////////////




///////////////////////////// Select Product ///////////////////////////
let [product, setProduct] = useState([]);
function getProduct(cid) {
  let AUTH = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Authorization: `Bearer ${window.token}` },
  });

  AUTH.get("/Products/ProductByCategoryId/"+cid).then((reply) => {
    // console.log(reply.data.data);
    if(reply.status == 200){
      if(reply.data.status == "OK"){
        setProduct(reply.data.data);

      }
    }
  }).catch(err => {
   // console.log("err form get State: ",err);
    if (err.response.status == 401) {
      // console.log("Unauthorize");
    }
  });


}
///////////////////////////////////////////////////////////////////////


///////////////////////// Get Selected Product ////////////////////////
function getSelectProduct(e) {
  let product = e.target.value;
  const { name, value } = e.target;
  
  //console.log(product);
// getStockQty(product);

  setDetails({
    ...data,
    [name]: value,
  });

  setErrors({
    ...errors,
    [name]: "",
  });
}
/////////////////////////////////////////////////////////////////////



////////////////////////////// Get Date ////////////////////////////// 
function getDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
}
////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////
const handleChange = (e) => {
  const { name, value } = e.target;
  setDetails({ ...data, [name]: value });
};
////////////////////////////////////////////////////////////////////



//////////////////// Function for Add or Reduce Quantity ///////////////////
const [selectedValue, setSelectedValue,] = useState("add");

const handleRadioChange = (value) => {
  setSelectedValue(value);
};

function updateStockQuantity()
{

  let dataToSend = {};
  if(selectedValue=="reduce"){
    let dataToUpdate={
      ...data,
      qtyUpdate : parseInt(data.qtyUpdate)*(-1)
   };

   dataToSend = {
    ...dataToUpdate
   };
  }else{

  let dataToUpdate={
    ...data,
    qtyUpdate : parseInt(data.qtyUpdate)
  };

  dataToSend = {
    ...dataToUpdate
   };
}

// console.log("final data:",dataToSend);

axios.post(process.env.REACT_APP_BASE_URL + "/Stock/UpdateStock",
  dataToSend,
  {
    headers: {Authorization: `Bearer ${window.token}`,},
  }
).then((reply)=>{
  if(reply.status==200)
  {
    if(reply.data.status="OK")
    {
      alert("Quantity Update Successfully");
    }
  }
})

}
////////////////////////////////////////////////////////////////////



///////////////////// Function for Save Details ////////////////////
const handleSave = async(e) => {
  e.preventDefault();
  // console.log("A");
  
  if (validate()) {
    // Save the form
    // console.log(data);

    updateStockQuantity();
      
    setDetails({
    category: "",
    productCode: "",
    qtyUpdate: "",
    comment: "",
    });
    }

};

//////////////////////////////////////////////////////////////////////

useEffect(() => {
  getCategory();

}, []);



return (
  <>

    <div className="row mx-2 align-items-center justify-content-center">
      <div className="custom-div col-xl-6 col-lg-6 col-md-10 col-sm-12 align-items-center justify-content-center" style={{ marginTop: "100px" }}>

        {/****** Main Heading *******/}
        <div className="col">
          <div className="row p-2" style={{backgroundColor:'#bbccff'}}>
            <div className="text-dark" style={{ borderRadius: "5px",padding:'10px',backgroundColor:'#ccddff',textAlign:'center' }}>
              <h5 className=""> Stock Updatation </h5>
            </div>
             {/****** Category *******/}
            <div className="col">
              <div className="row mt-2">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                  <label>Category Name</label>
                  <select
                    id="category"
                    value={data.category}
                    name="category"
                    onChange={getSelectCategory}
                    className={`form-control ${errors.category ? "is-invalid" : ""}`}>
                      <option disabled={data.category? true : false} >Select Category</option>
                    {category.map((e, i) => (
                      <option value={e.cid}>{e.categoryName}</option>
                    ))}
                  </select>
                  {errors.category && (<p className="text-danger">{errors.category}</p>)}
                </div>   

                {/****** Date *******/}
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <label>Date</label>
                  <input type="text" className="form-control" id="date" name="date" value={getDate()} readOnly />
                </div>
              </div>

              {/****** Product *******/}
              <div className="row mt-2">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                  <label>Product Name</label>
                  <select
                    id="product"
                    value={data.productCode}
                    name="productCode"
                    onChange={getSelectProduct}
                    className={`form-control ${errors.productCode ? "is-invalid" : ""}`}>
                      <option disabled={data.product? true : false} >Select Product</option>
                    {product.map((e, i) => (
                      <option value={e.productCode}>{e.productName}</option>
                    ))}
                  </select>
                  {errors.productCode && (<p className="text-danger">{errors.productCode}</p>)}
                </div>

                {/****** qtyUpdate *******/}
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <label>Quantity</label>
                  <input type="number"
                    className={`form-control ${errors.qtyUpdate ? "is-invalid" : ""}`}
                   id="qty" name="qtyUpdate" value={data.qtyUpdate} onChange={handleChange} />
                  {errors.qtyUpdate && (<p className="text-danger">{errors.qtyUpdate}</p>)}
                </div>
              </div>
            </div>
          

            {/****** comment *******/}
            <div className="row mt-2">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <label>Comment</label>
                <br />
                <textarea className="form-control" name="comment" value={data.comment} onChange={handleChange} ></textarea>
                {errors.comment && (<p className="text-danger">{errors.comment}</p>)}
              </div>
            </div>
          
            {/****** Radio And Save Buttons *******/}
            <div className="row mt-2">
              <div className="col">
                <input type="radio" id="addStock"  value="add" checked={selectedValue=="add"} onChange={(e)=>handleRadioChange(e.target.value)} /> Add
                <input type="radio" id="reduce"  className="ms-5" value="reduce" checked={selectedValue=="reduce"} onChange={(e)=>handleRadioChange(e.target.value)} /> Reduce <br />
                <button className="btn custom-button mt-2" onClick={handleSave}> Save </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ShowStock />
  </>
);
}
let connectToStore = (state) => ({ commonData: state });
export default connect(connectToStore)(AddStockDetails);  