import axios from "axios";
import { useEffect, useState } from "react";

function ViewMore(params)
{   
    
//console.log(params.item);

//////////////////////// Confirm Button ////////////////////////
let handleConfirm=()=>{
    let amount=document.getElementById("amnt").value;
    if(window.confirm("Are You Sure?"))
    {
    axios.post(process.env.REACT_APP_BASE_URL + "/ItemReturn/ApproveRequest/"+ params.item.returnId+ "/" + amount, {}, {
        headers:{Authorization:`Bearer ${window.token}`}
    }
      ).then(reply=>{
        if(reply.status==200)
        {
          if(reply.data.status="OK")
          {
            alert("Request Confirmed Succesfully!");
          }
        }
    })
    }
}
///////////////////////////////////////////////////////////////



//////////////////////// Reject Button ////////////////////////
let handleReject=()=>{
    if (window.confirm("Are You Sure?"))
    {
    axios.post(process.env.REACT_APP_BASE_URL+ "/ItemReturn/RejectRequest/" + params.item.returnId, {},{
        headers:{Authorization:`Bearer ${window.token}`}
    }
    ).then(reply=>{
        if(reply.status == 200)
        {
            if(reply.data.status == "OK")
            {   
                alert("Request Rejected Succesfully!");
            }
        }
    })
    }
}
///////////////////////////////////////////////////////////////



//////////////////////// Cancel Button ////////////////////////
let handleCancel=()=>{
    params.toClose(false);
}
///////////////////////////////////////////////////////////////



////////////////// Show Images on View More //////////////////
let [img, setImg]=useState([]);
let showImages=()=>{
    axios.post(process.env.REACT_APP_BASE_URL+"/ItemReturn/GetPhotos/"+ params.item.returnId, {},{
        headers:{Authorization:`Bearer ${window.token}`}
    } ).then(reply => {
    // console.log(reply.data.data[0].photo);
    setImg(reply.data.data);
    });
}
///////////////////////////////////////////////////////////////


useEffect(()=>{
    showImages();
},[])

    return(
        <>
        <div className="custom-popup-div" >  
        <div style={{overflow:"auto"}} className=" popup-div custom-pop-div row mx-2 p-5 my-2 align-items-center justify-content-center">
            <div className="custom-div col-xl-8 col-lg-8 col-md-10 col-sm-12 pb-3 align-items-center justify-content-center ">
                <div className="col">
                        <div className="row">
                            {/****** Batch & Voucher Number *******/}
                            <div className="col">
                                <div className="row mt-2">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <label>Batch Number</label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-sm" 
                                            name="batchNo"
                                            value={params.item.batchNo}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <label>Voucher Number</label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-sm"
                                            name="voucherNo"
                                            value={params.item.voucherNo}
                                            readOnly
                                        />
                                       
                                    </div>
                                </div>

                                {/****** Product *******/}
                                <div className="row mt-2">
                                    <div className="col-xl-4 col-lg-4 col-md-8 col-sm-12">
                                        <label>Product Code</label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-sm"
                                            name="productCode"
                                            value={params.item.productCode}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                        <label>Product Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-sm"
                                            name="productName"
                                            value={params.item.productName}
                                            readOnly
                                        />                                    
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/****** Material & Vehicle Type ******/}
                        <div className="row mt-2">
                            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                <label> Material </label>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm"
                                    name="material"
                                    value={params.item.material}
                                    readOnly
                                />
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                <label> Vehicle Type </label>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm"
                                    name="vehicleType"
                                    value={params.item.vehicleType}
                                    readOnly
                                />
                            </div>
                        </div>

                        {/****** Remark *******/}
                        <div className="row mt-2">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <label>Remark</label>
                                <br />
                                <textarea 
                                    className="form-control form-control-sm" 
                                    name="remark"
                                    value={params.item.remark}
                                />
                            </div>
                        </div>

                        {/****** Photos ******/}
                        <div className="row mt-2">
                            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                <label>Photos</label>
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Photo of Complete Product</label>
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                        {img[0] && <img alt="No Photo Seleted" src={"data:image/*;base64,"+img[0].photo } style={{width: "180px", height: "180px"}}/>}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Photo with Brand Name</label>
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                        {img[1] && <img alt="No Photo Seleted" src={"data:image/*;base64,"+img[1].photo } style={{width: "180px", height: "180px"}}/>}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Photo with Product Details</label>
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                    {img[2] && <img alt="No Photo Seleted" src={"data:image/*;base64,"+img[2].photo } style={{width: "180px", height: "180px"}}/>}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Damage Part Photo 1</label>
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                    {img[3] && <img alt="No Photo Seleted" src={"data:image/*;base64,"+img[3].photo } style={{width: "180px", height: "180px"}}/>}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Damage Part Photo 2</label>
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                    {img[4] && <img alt="No Photo Seleted" src={"data:image/*;base64,"+img[4].photo } style={{width: "180px", height: "180px"}}/>}
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* Amount */}
                        <div className="row mt-4 justify-content-left">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <label>Amount</label>
                                <input 
                                type="number" 
                                id="amnt" 
                                name="amount" 
                                className="form-control form-control-sm-sm" 
                                />
                            </div>
                        </div>


                        {/****** Save Button *******/}
                        <div className="row mt-4 justify-content-left">
                            <div className="col-3 d-flex">
                                <button className="btn custom-button" onClick={handleConfirm}> Approve </button>
                                <button className="btn custom-button" onClick={handleReject}> Reject </button>
                                <button className="btn btn-secondary" onClick={handleCancel}> Cancel </button>
                            </div>
                        </div>

                    </div>
            </div>
        </div>
        </div>
        </>
    );
}
export default ViewMore; 