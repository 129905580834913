import { useEffect, useState } from "react";
import API_AUTH from "../../../api_auth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OrderCard from "./orderCard";

function ViewOrders() {

    let nav = useNavigate();
    let [orders, setOrders] = useState([]);
    let login = useSelector((state) => state.isLogin);

    function getAllOrders(){
        API_AUTH().get("/PurchaseOrder/GetAllOrdersByDealerCode").then(reply=>{
            if(reply.status === 200){
                if(reply.data.status === "OK"){
                    setOrders(reply.data.data);
                }
            }
        }
        )
    }

    useEffect(()=>{
        
        if(login === "N"){
            nav("/");
        }
        else{
            getAllOrders();
        }
        
    },[])
    
    return(
        <>
        <div className="row" style={{
            top:"0",
            left:"0",
            right:"0",
            bottom:"0",
            backgroundColor:"white",
            position:"fixed",

        }}>
            <div className="col" style={{marginTop:"100px", marginLeft:"20px", marginRight:"20px"}}>
                <h2 style={{marginLeft:"20px"}}>My Orders</h2>
                {orders.map((e, i)=>(
                    <OrderCard orderId={e.orderId} orderDate={e.orderDate} amount={e.amount} orderStatus={e.orderStatus} />
                ))}
            </div>
        </div>
        </>
    )
}


export default ViewOrders;