import axios from "axios";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import edit from '../images/edit.png';
import API_AUTH from "../../api_auth";


function AddCategory({commonData}) {
  let [button, setButton] = useState("Save");
  let nav = useNavigate();

  // Form Validation
  let [errors, setErrors] = useState({
      categoryName : "",
    categoryDetails: ""
  });

  let [formData, setFormData] = useState({
    Cid:0,
    CategoryName : "",
    CategoryDetails: ""
  });

  function handleChange(e){
    const { name, value } = e.target;

    setErrors({
      ...errors,
      [name]: "",
      
    })

    setFormData({
      ...formData, [name]: value
    })
  }

  function validateForm(){
    let newErrors = {};
    let errorMessage = [];

    if(!formData.CategoryName){
      newErrors.CategoryName = "Category Name is required!";
      errorMessage.push(newErrors.CategoryName);
    }
    if(!formData.CategoryDetails){
      newErrors.CategoryDetails = "Category Details is required!";
      errorMessage.push(newErrors.CategoryDetails);
    }

    setErrors(newErrors);

    if(errorMessage.length > 0){
      alert(errorMessage.join("\n"));
    }

    return Object.keys(newErrors).length === 0;

  };

    // Get Category
    /*function getCategory()
    {

       API_AUTH().get("/Categories/AllCategories").then(reply =>{
        if(reply.status == 200){
          if(reply.data.status){
            // console.log(reply.data.data);
            setCategory(reply.data.data);
          }
          else{
            console.log("error:"+ reply.data.message);
          }
        }
        else{
          console.log(reply);
        }
      })
    }*/


    // Save Data
    const handleSubmit = async (e)=>{
      e.preventDefault();
      const isValid = validateForm();
      if (isValid) {
        console.log(formData);
        // Send data to server
        await axios.post(process.env.REACT_APP_BASE_URL+"/Categories/SaveCategory", formData, {
          headers: {
            Authorization: `Bearer ${window.token}`
          }
        }).then(reply=>{
          if(reply.data.status == "OK"){
              
              alert("Category Added!");
              getCategory();
              setFormData({
                CategoryName : "",
                CategoryDetails: ""
              })
          }
          else{
            console.log(reply.data.message);
          }
        }).catch(err=>{
          if(err){
            console.log(err);
          }
        })
      } else {
        console.log("form validation failed");
        
      }
    }

    // update category
    async function updateCategory() {
      await axios.post(process.env.REACT_APP_BASE_URL+"/Categories/UpdateCategory", formData, {
        headers: {
          Authorization: `Bearer ${window.token}`
        }
      }).then(reply=>{
        if(reply.data.status == "OK"){
            // console.log("Data save");
            alert("Category Updated!");
  
            setFormData({
              CategoryName : "",
              CategoryDetails: ""
            })
            setButton("Save");
        }
        else{
          alert(reply.data.status+"\n"+ reply.data.message);
        }
      }).catch(err=>{
        if(err){
          console.log(err);
        }
      })
    }

    

    //***************************** category table *******************************//
  let [categoryData, setCategoryData] = useState([]);
  let [categoryLength, setCategoryLenth] = useState();
  let [searchQuery, setSearchQuery] = useState("");
  const [filteredCategory, setFilteredCategory] = useState([]);

  function handleSearchChange(event) {
    setSearchQuery(event.target.value);
  }
  function getCategory() {

    API_AUTH().get("/Categories/AllCategories").then((reply) => {
      setCategoryLenth(reply.data.data.length);
      setCategoryData(reply.data.data);
      setFilteredCategory(reply.data.data);
    }).catch(err=>{
      if(err){
      if(err.response.status == 401){
        // console.log("Unauthorize");
      }
    }
    })
  }

  function getCategoryByCid(cid){
    
    API_AUTH().get("/Categories/CategoryById/"+cid).then((reply) => {
      if(reply.status == 200){
        if(reply.data.status == "OK"){
          setButton("Update");
          setFormData({
            Cid:reply.data.data.cid,
            CategoryName : reply.data.data.categoryName,
            CategoryDetails: reply.data.data.categoryDetails 
          })
        }
      }
      
    }).catch(err=>{
      if(err){
      if(err.response.status == 401){
        // console.log("Unauthorize");
      }
    }
    })
  }

  useEffect(() => {
    getCategory();
    /*if (typeof searchQuery === "string") {
      const lowerCaseQuery = searchQuery.toLowerCase();
      setFilteredCategory(
        categoryData.filter((dealer) =>
          Object.values(dealer).some((value) =>
            value?.toString().toLowerCase().includes(lowerCaseQuery)
          )
        )
      );
    } else {
      setFilteredCategory(categoryData);
    }*/
  }, []);
  return (
    <>
      <div className="row container-fluid justify-content-center">
      <div style={{marginTop:'100px',backgroundColor:'rgb(204, 221, 255)'}} className="custom-div col-xl-6 col-lg-6 col-md-8 col-sm-10">

              <div style={{backgroundColor:"#bbccff", color:"black", borderRadius:"10px",marginTop:'10px',textAlign:'center'}}>
                <h6 className="p-2">Add New Category</h6>
              </div>

          <div className="form-group">
          <label>Category Name</label>
          <input
            
           id="IDCname"
            className={`form-control ${errors.CategoryName ? "is-invalid" : ""}`}
            name="CategoryName"
            isInvalid={!!errors.CategoryName}
            value={formData.CategoryName}
            onChange={handleChange}
            
             />

          <div className="error" type="invalid">
            {errors.CategoryName}
            
          </div>
          </div>
       
          <div className="mt-2 from-group">
          <label>Category Details</label>
          <input
            
            id="IDCdetails"
           className={`form-control ${errors.CategoryDetails ? "is-invalid" : ""}`}
           isInvalid={!!errors.CategoryDetails}
           name="CategoryDetails"
           value={formData.CategoryDetails}
           
           onChange={handleChange} />

        {errors.CategoryDetails && <div className="error" >{errors.CategoryDetails}</div> }
        </div>
       
          <div className="form-group">
          <button onClick={button == "Save"? handleSubmit : updateCategory}  className="custom-button my-2 btn btn-primary " style={{}}>{button}</button>
          
        </div>

      </div>

      <div className="row mb-2 align-items-center justify-content-center">
      <div style={{marginTop:'50px',backgroundColor:'#ccddff'}} className="custom-div col-xl-8 col-lg-8 col-md-8 col-sm-12 align-items-center justify-content-center">
      <>
    <div className="mt-2">
      <h4>All Categories</h4>
    </div>
      <div className="row mt-3" >
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
          <label>Total Categories:</label>
          <h5>{categoryLength}</h5>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <input
            type="text"
            placeholder="search"
            className="form-control"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <hr />
      <div style={{ overflow: "auto",height:"30rem"}}>
        <table className="table">
          <thead>
            <tr>
              <th>Category ID</th>
              <th>Category Name</th>
              <th>Category Details</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredCategory.map((c, i) => (
              <tr className="table-row">
                <td>{c.cid}</td>
                <td>{c.categoryName}</td>
                <td>{c.categoryDetails}</td>
                <td><img src={edit} onClick={()=>getCategoryByCid(c.cid)} style={{width:"30px", cursor:"pointer" }}/></td>
                {/* <td><img src={deletebtn} style={{width:"30px"}} /></td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
      </div>
      </div>
      </div>  
    </>
  );
}

let connectToStore=(state)=>({commonData: state});
export default connect(connectToStore) (AddCategory);