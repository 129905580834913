import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogin } from "../action";

function Logout({commonData, setLogin}){
    let nav = useNavigate();
    setLogin("N");
    nav("/");
}

let connectToStore=(state)=>({commonData: state});
let dispatchToStore = (dispatch) => ({
    setLogin: (value) => dispatch(setLogin(value)),
  });
export default connect(connectToStore, dispatchToStore) (Logout);