import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import avatar from './images/avatar.png';

import {
  CNavbarNav,
  CCollapse,
  CNavItem,
  CNavLink,
  CNavbarToggler,
} from "@coreui/react";

function AdminHeader() {

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  function closeNav(){
    setIsOpen(false);
  }
    
    return(
      <>
      <CNavbarToggler
            aria-label="Toggle navigation"
            aria-expanded={isOpen}
            onClick={toggleNavbar}
            />
      <CCollapse className="navbar-collapse" visible={isOpen}>
        <CNavbarNav>
              <CNavItem>
              <CNavLink><Link className="nav-link" to="/category" onClick={closeNav}>Category</Link></CNavLink>
              </CNavItem>

              <CNavItem>
              <CNavLink><Link className="nav-link" to="/product" onClick={closeNav}>Product</Link></CNavLink>
              </CNavItem>

              <CNavItem>
              <CNavLink><Link className="nav-link" to="/stock" onClick={closeNav}>Stock</Link></CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink><Link className="nav-link" to="/dealer" onClick={closeNav}>Dealer</Link></CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink><Link className="nav-link" to="/returnItemAdmin" onClick={closeNav}>Return Item</Link></CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink><Link className="nav-link" to="/savePaymentDetails" onClick={closeNav}>Transaction</Link></CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink><Link className="nav-link" to="/replyToQuery" onClick={closeNav}>Query</Link></CNavLink>
              </CNavItem>
            </CNavbarNav>

            
            <div className="ms-auto d-flex">

            <Link to="/adminDashboard"><button className="btn border dashboard-btn ">Dashboard</button></Link>
            <label style={{marginTop:"6px", marginLeft:"10px"}}>Hi, Admin!</label>
            <NavDropdown align={{ sm:'end', md:'start', lg:'start', xl:'start', xxl:'start' }} title={<img src={avatar} style={{width:"30px"}}></img>} className="btn">

            <LinkContainer to="/createUser">
              <NavDropdown.Item>Create/Manage User</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/changeAdminPassword">
              <NavDropdown.Item>Change Password</NavDropdown.Item>
            </LinkContainer>

            <LinkContainer to="/logout">
              <NavDropdown.Item>Logout</NavDropdown.Item>
            </LinkContainer>
            
          </NavDropdown>
            
            </div>
            </CCollapse>

            </>
    )
}

export default AdminHeader;