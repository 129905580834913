import axios from "axios";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import edit from '../images/edit.png';
import deletebtn from '../images/dlt.png';

function AddDealer({ commonData }) {
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [button, setButton] = useState("Save");
  let nav = useNavigate();

  // API Authenticaiton
  function API_AUTH() {
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: { Authorization: `Bearer ${window.token}` },
    });
  }
  // ------------------ //

  // Form Data 
  let [formData, setFormData] = useState({
    dealerCode: "",
    companyName: "",
    contactPerson: "",
    gst: "",
    pan: "",
    email: "",
    mobile1: "",
    mobile2: "",
    address: "",
    state: "",
    city: "",
    pinCode: "",
    creditLimit: "",
    activationDate: "",
    bankAccountNo: "",
    bankName: "",
    ifsCode: "",
    micrCode: "",
    branchAddress: "",
    status: "",
  });

  const [errors, setErrors] = useState({
    dealerCode: "",
    companyName: "",
    contactPerson: "",
    gst: "",
    pan: "",
    email: "",
    mobile1: "",
    mobile2: "",
    address: "",
    state: "",
    city: "",
    pinCode: "",
    creditLimit: "",
    bankAccountNo: "",
    bankName: "",
    ifsCode: "",
    micrCode: "",
    branchAddress: "",
  });

  // form Validation
  function ValidateForm() {
    let newError = {};
    let errorMessage = [];

    if (!formData.dealerCode) {
      newError.dealerCode = "Dealer Code is required!";
      errorMessage.push(newError.dealerCode);
    }
    if (!formData.companyName) {
      newError.companyName = "Company Name is required!";
      errorMessage.push(newError.companyName);
    }
    if (!formData.contactPerson) {
      newError.contactPerson = "Contact Person is required!";
      errorMessage.push(newError.contactPerson);
    }
    if (!formData.gst) {
      newError.gst = "GST is required!";
      errorMessage.push(newError.gst);
    }
    if (!formData.pan) {
      newError.pan = "PAN is required!";
      errorMessage.push(newError.pan);
    }
    if (!formData.email) {
      newError.email = "Email is required!";
      errorMessage.push(newError.email);
    }
    if (!formData.mobile1) {
      newError.mobile1 = "Mobile Number is required!";
      errorMessage.push(newError.mobile1);
    }
    if (!formData.address) {
      newError.address = "Address is required!";
      errorMessage.push(newError.address);
    }
    if (!formData.state) {
      newError.state = "State is required!";
      errorMessage.push(newError.state);
    }
    if (!formData.city) {
      newError.city = "City is required!";
      errorMessage.push(newError.city);
    }
    if (!formData.pinCode) {
      newError.pinCode = "Pin Code is required!";
      errorMessage.push(newError.pinCode);
    }
    if (!formData.creditLimit) {
      newError.creditLimit = "Credit Limit is required!";
      errorMessage.push(newError.creditLimit);
    }
    if (!formData.bankAccountNo) {
      newError.bankAccountNo = "Bank Account No is required!";
      errorMessage.push(newError.bankAccountNo);
    }
    if (!formData.bankName) {
      newError.bankName = "Bank Name is required!";
      errorMessage.push(newError.bankName);
    }
    if (!formData.ifsCode) {
      newError.ifsCode = "IFSC is required!";
      errorMessage.push(newError.ifsCode);
    }
    if (!formData.micrCode) {
      newError.micrCode = "MICR Code is required!";
      errorMessage.push(newError.micrCode);
    }
    if (!formData.branchAddress) {
      newError.branchAddress = "Branch Address is required!";
      errorMessage.push(newError.branchAddress);
    }

    setErrors(newError);

    if(errorMessage.length > 0){
      alert(errorMessage.join("\n"));
    }

    return Object.keys(newError).length === 0;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  // GET Dealer Data
  async function dealerDetails() {
    if (formData.dealerCode == "") {
    } else {
      
      API_AUTH().get("/Dealers/GetDealer/" + formData.dealerCode).then((reply) => {
        if (reply.status == 200) {
          if (reply.data.status == "OK") {            
            if (reply.data.data) {
              setButton("Update");

              // set form Data 
              // document.getElementById("idState").value = reply.data.data.state;
              setFormData({
                dealerCode: reply.data.data.dealerCode,
                companyName: reply.data.data.companyName,
                contactPerson: reply.data.data.contactPerson,
                gst: reply.data.data.gst,
                pan: reply.data.data.pan,
                email: reply.data.data.email,
                mobile1: reply.data.data.mobile1,
                mobile2: reply.data.data.mobile2,
                address: reply.data.data.mobile2,
                state: reply.data.data.state,
                city: reply.data.data.city,
                pinCode: reply.data.data.pinCode,
                creditLimit: reply.data.data.creditLimit,
                activationDate: reply.data.data.activationDate,
                bankAccountNo: reply.data.data.bankAccountNo,
                bankName: reply.data.data.bankName,
                ifsCode: reply.data.data.ifsCode,
                micrCode: reply.data.data.micrCode,
                branchAddress: reply.data.data.branchAddress,
                status: reply.data.data.status
              });
            } else {
              setButton("Save");

              // setFormData({
              //   companyName: "",
              
              //   contactPerson: "",
              //   gst: "",
              //   pan: "",
              //   email: "",
              //   mobile1: "",
              //   mobile2: "",
              //   address: "",
              //   state: "",
              //   city: "",
              //   pinCode: "",
              //   creditLimit: "",
              //   activationDate: "",
              //   bankAccountNo: "",
              //   bankName: "",
              //   ifsCode: "",
              //   micrCode: "",
              //   branchAddress: "",
              //   status: "",
              // });
            }
          } else {
            console.log(reply.data.status);
          }
        } else {
          console.log(reply);
        }
      });
    }
  }

  // GET STATE
  function getStates() {

    API_AUTH().get("/StateCity/states").then((reply) => {
      //console.log(reply.data.data);
      setState(reply.data.data);
    }).catch(err=>{
      // console.log("err form get State: ",err);
      if(err.response.status == 401){
        // console.log("Unauthorize");
      }
    })
  }

  // Get Selected State
  function getSelectState(e) {
    let state = e.target.value;
    const { name, value } = e.target;
    
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });

    API_AUTH().get("/StateCity/cities/" + state).then((reply) => {
      // console.log(reply.data.data);
      setCity(reply.data.data);
    }).catch(err=>{
      if(err){
        if(err.response.status == 401){
          console.log("Unautherize");
        }
      }
    })
  }

  // handle Pin Code input
  const handlePincode = (e) => {
    const { name, value } = e.target;

    if (!/[0-9]/.test(e.key) || formData[name].length >= 6) {
      e.preventDefault();
    }
  };

  const handlePinCodePaste = (e) => {
    const { name, value } = e.target;
    const paste = (e.clipboardData || window.clipboardData).getData("text");
    if (!/^\d+$/.test(paste) || formData[name].length >= 6) {
      e.preventDefault();
    }
  };

  // handle Mobile number input
  const handleKeyPress = (e) => {
    const { name, value } = e.target;
    if (!/[0-9]/.test(e.key) || formData[name].length >= 10) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    const { name, value } = e.target;
    const paste = (e.clipboardData || window.clipboardData).getData("text");
    if (!/^\d+$/.test(paste) || formData[name].length >= 10) {
      e.preventDefault();
    }
  };

  // Clear Form Data
  function clearFormData() {
    setFormData({
      dealerCode: "",
      companyName: "",
      contactPerson: "",
      gst: "",
      pan: "",
      email: "",
      mobile1: "",
      mobile2: "",
      address: "",
      state: "",
      city: "",
      pinCode: "",
      creditLimit: "",
      bankAccountNo: "",
      bankName: "",
      ifsCode: "",
      micrCode: "",
      branchAddress: "",
    });
  }

  // Get Date
  function getDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Save data
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (ValidateForm()) {
      try {

        let dataToSend = {
          ...formData,
          creditLimit: parseInt(formData.creditLimit, 10),
          activationDate: getDate(),
          status: "E",
        };
        // console.log(dataToSend);

      //   // Send data to server
        await axios
          .post(
            process.env.REACT_APP_BASE_URL + "/Dealers/SaveDealer",
            dataToSend,
            {
              headers: {
                Authorization: `Bearer ${window.token}`,
              },
            }
          )
          .then((reply) => {
            if (reply.status == 200) {
              if (reply.data.status == "OK") {
                // console.log("Dealer Save");
                alert("Dealer Added!");
                clearFormData();
              
              } 
              else {
                console.log(reply.data.message);
                alert("Dealer already exist!");
              }
            } 
            else {
              console.log(reply);
              alert(reply);
            }
          });
      } catch (error) {
        if (error.inner && error.inner.length > 0) {
          const newError = {};

          error.inner.forEach((err) => {
            newError[err.path] = err.message;
          });
          setErrors(newError);
        } else {
          console.log("Validation Error", error);
        }
      }
    }
  };

  // Update Dealer
  async function updateDealer(){

    // console.log(formData);
    await axios.post(process.env.REACT_APP_BASE_URL+"/Dealers/UpdateDealer", formData, {

      headers:{ Authorization: `Bearer ${window.token}`}

    }).then(reply=>{
        if(reply.status == 200){
            if(reply.data.status == "OK"){
                alert(reply.data.message);
                // console.log(formData);
                clearFormData();
            }
            else{
              console.log(reply.data);
            }
        }else{
          console.log(reply.status);
        }
  }).catch(err=>{
    if (err) {
      console.log(err);
    }
  })
  }

  // Dealer Table
  
  let [dealersData, setDealersData] = useState([]);
  let [dealerLength, setDealerLenth] = useState();
  let [searchQuery, setSearchQuery] = useState("");
  const [filteredDealers, setFilteredDealers] = useState([]);

  function getDealer() {

    API_AUTH().get("/Dealers/GetAllDealers").then((reply) => {
      // console.log(reply.data.data);
      setDealerLenth(reply.data.data.length);
      setDealersData(reply.data.data);
    }).catch(err=>{
      if(err){
      if(err.response.status == 401){
        // console.log("Unauthorize");
      }
    }
    })
  }

  function handleSearchChange(event) {
    setSearchQuery(event.target.value);
  }

  async function deleteDealer(dCode){

    // console.log("delete dealer: "+dCode);

    await axios.post(process.env.REACT_APP_BASE_URL+"/Dealers/DeleteDelaer/"+dCode, {}, {
        headers: {
        Authorization: `Bearer ${window.token}`
      }}
    ).then(reply=>{
        if(reply.status == 200){
          if(reply.data.status == "OK"){
            alert(reply.data.message);
          }
        }
        else{
          console.log(reply.status);
        }

    }).catch(err=>{
      if(err){
        console.log(err);
      }
    })

  }

  // update Dealer using table
  function editDealer(dCode){
    formData.dealerCode = dCode;
    dealerDetails();
  }


  useEffect(() => {
    if (typeof searchQuery === "string") {
      const lowerCaseQuery = searchQuery.toLowerCase();
      setFilteredDealers(
        dealersData.filter((dealer) =>
          Object.values(dealer).some((value) =>
            value?.toString().toLowerCase().includes(lowerCaseQuery)
          )
        )
      );
    } else {
      setFilteredDealers(dealersData);
    }
  }, [searchQuery, dealersData]);

  useEffect(() => {
    
    if (commonData.isLogin == "N") {
      nav("/");

    }
    else{
      getStates();
      getDealer();
    
    }


  }, [dealersData]);

  return (
    <>
      <div className="row mx-2 align-items-center justify-content-center">
        <div style={{ marginTop: "100px",backgroundColor:'#ccddff' }}
          className="custom-div col-xl-8 col-lg-8 col-md-8 pb-3 align-items-center justify-content-center"
          >
          <div
            style={{
              width: "100%",
              
              borderRadius: "5px",
              backgroundColor:"#bbccff"
            }}
            className="mt-2 mx-0 "
          >
            <h5 className="my-2 mx-2 py-2">Dealer Details</h5>
          </div>

          <div className="col">
            <div className="row mt-2">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label>Dealer Code</label>  
                <input
                value={formData.dealerCode}
                onBlur={dealerDetails}
                name="dealerCode"
                onChange={handleChange}
                className={`form-control ${errors.dealerCode? "is-invalid": ""}`}
                />
                <div className="error">{errors.dealerCode}</div>
              </div>

              <div className="col">
                <label>Company Name</label>
                <input
                  value={formData.companyName}
                  id="idCompanyName"
                  onChange={handleChange}
                  name="companyName"
                  type="text"
                  className={`form-control ${
                    errors.companyName ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.companyName}</div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label>Contact Person</label>
                <input
                  id="idContactPerson"
                  value={formData.contactPerson}
                  onChange={handleChange}
                  name="contactPerson"
                  type="text"
                  className={`form-control ${
                    errors.contactPerson ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.contactPerson}</div>
              </div>
            </div>

            <div className="row mt-2"></div>

            <div className="row mt-2">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label>GST</label>
                <input
                  id="idGst"
                  value={formData.gst}
                  onChange={handleChange}
                  name="gst"
                  type="text"
                  className={`form-control ${errors.gst ? "is-invalid" : ""}`}
                />
                <div className="error">{errors.gst}</div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label>PAN</label>
                <input
                  id="idPan"
                  value={formData.pan}
                  onChange={handleChange}
                  name="pan"
                  type="text"
                  className={`form-control ${errors.pan ? "is-invalid" : ""}`}
                />
                <div className="error">{errors.pan}</div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label>Email</label>
                <input
                  id="idEmail"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                />
                <div className="error">{errors.email}</div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <label>Mobile No.</label>
                <input
                  id="idMobile1"
                  value={formData.mobile1}
                  onKeyPress={handleKeyPress}
                  onPaste={handlePaste}
                  onChange={handleChange}
                  name="mobile1"
                  type="text"
                  className={`form-control ${
                    errors.mobile1 ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.mobile1}</div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <label>Alternate Mobile No.</label>
                <input
                  id="idMobile2"
                  value={formData.mobile2}
                  onKeyPress={handleKeyPress}
                  onPaste={handlePaste}
                  onChange={handleChange}
                  name="mobile2"
                  type="text"
                  className={`form-control ${
                    errors.mobile2 ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.mobile2}</div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label>Address</label>
                <textarea
                  id="idAddress"
                  value={formData.address}
                  onChange={handleChange}
                  name="address"
                  className={`form-control ${
                    errors.address ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.address}</div>
              </div>
            </div>

            {/* Get States */}
            <div className="row mt-2">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label>State</label>
                <select
                value={formData.state}
                  id="idState"
                  name="state"
                  onChange={getSelectState}
                  className={`form-control ${errors.state ? "is-invalid" : ""}`}
                >
                  <option disabled={formData.state? true : false}>Select</option>
                  {state.map((e, i) => (
                    <option value={e} selected={e==formData.state}>{e}</option>
                  ))}
                </select>

                <div className="error">{errors.state}</div>
              </div>

              {/* Get City */}
              <div className="col">
                <label>City</label>
                <select
                key={formData.city}
                  id="idCity"
                  value={formData.city}
                  onChange={handleChange}
                  name="city"
                  className={`form-control ${errors.city ? "is-invalid" : ""}`}
                >
                  <option disabled={formData.city? true : false}>Select</option>
                  {city.map((c, i) => (
                    <option value={c}>{c}</option>
                  ))}
                </select>
                <div className="error">{errors.city}</div>
              </div>
              <div className="col">
                <label>Pin Code</label>
                <input
                  id="idPinCode"
                  value={formData.pinCode}
                  onKeyPress={handlePincode}
                  onPaste={handlePinCodePaste}
                  onChange={handleChange}
                  name="pinCode"
                  type="text"
                  className={`form-control ${
                    errors.pinCode ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.pinCode}</div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <label>Credit Limit</label>
                <input
                  id="idCreditLimit"
                  value={formData.creditLimit}
                  onChange={handleChange}
                  name="creditLimit"
                  type="number"
                  className={`form-control ${
                    errors.creditLimit ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.creditLimit}</div>
              </div>
              {/* <div className="col">
                <label>Ativation Date</label>
                <input
                  onChange={handleChange}
                  name="activationDate"
                  type="text"
                  value={getDate()}
                  className={`form-control ${errors.activationDate ? "is-invalid" : ""}`}
                />
                <div className="error">{errors.activationDate}</div>
              </div> */}
            </div>
            <div
              style={{
                backgroundColor:"#bbccff",
                borderRadius: "5px",
              }}
              className="my-3 "
            >
              <h5 className="my-2 mx-2 py-2">Bank Details</h5>
            </div>

            <div className="row mt-2">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label>Bank Account No.</label>
                <input
                  id="idBankAccountNo"
                  value={formData.bankAccountNo}
                  onChange={handleChange}
                  name="bankAccountNo"
                  type="text"
                  className={`form-control ${
                    errors.bankAccountNo ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.bankAccountNo}</div>
              </div>
              <div className="col">
                <label>Bank Name</label>
                <input
                  id="idBankName"
                  value={formData.bankName}
                  onChange={handleChange}
                  name="bankName"
                  type="text"
                  className={`form-control ${
                    errors.bankName ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.bankName}</div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label>IFSCode</label>
                <input
                  id="idIfsCode"
                  value={formData.ifsCode}
                  onChange={handleChange}
                  name="ifsCode"
                  type="text"
                  className={`form-control ${
                    errors.ifsCode ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.ifsCode}</div>
              </div>
              <div className="col">
                <label>MICR Code</label>
                <input
                  id="idMicrCode"
                  value={formData.micrCode}
                  onChange={handleChange}
                  name="micrCode"
                  type="text"
                  className={`form-control ${
                    errors.micrCode ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.micrCode}</div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label>Bank Address</label>
                <textarea
                  id="idBranchAddress"
                  value={formData.branchAddress}
                  onChange={handleChange}
                  name="branchAddress"
                  className={`form-control ${
                    errors.branchAddress ? "is-invalid" : ""
                  }`}
                />
                <div className="error">{errors.branchAddress}</div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button
                  onClick={button == "Save"? handleSubmit : updateDealer}
                  className="btn btn-primary my-2"
                >
                  {button}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "100px" }}
          className="custom-div col-xl-8 col-lg-8 col-md-8 pb-3 align-items-center justify-content-center"
        >
         <>
    <div className="mt-2">
      <h4>All Dealers</h4>
    </div>
      <div className="row mt-3" >
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
          <label>Total Dealer:</label>
          <h4>{dealerLength}</h4>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <input
            type="text"
            placeholder="search"
            className="form-control"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <hr />
      <div style={{ overflow: "auto",height:"30rem"}}>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Dealer Code</th>
              <th>Company Name</th>
              <th>Contact Person</th>
              <th>Mobile Number</th>
              <th>Alternate Number</th>
              <th>Credit Limit</th>
              <th></th>
              <th></th>
            
            </tr>
          </thead>
          <tbody>
            {filteredDealers.map((d, i) => (
              <tr
                className="table-row"
                
              >
                <td>{d.dealerCode}</td>
                <td>{d.companyName}</td>
                <td>{d.contactPerson}</td>
                <td>{d.mobile1}</td>
                <td>{d.mobile2}</td>
                <td>{d.creditLimit}</td>
                <td><img src={edit} onClick={()=> editDealer(d.dealerCode)} style={{width:"30px",cursor:'pointer' }}/></td>
                <td><img  src={deletebtn} onClick={()=> window.confirm("Are you Sure?") ? deleteDealer(d.dealerCode) : ""} style={{width:"30px", cursor:"pointer"}} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
        </div>
      </div>
    </>
  );
}
let connectToStore = (state) => ({ commonData: state });
export default connect(connectToStore)(AddDealer);
