import { useState } from "react";
import API_AUTH from '../../api_auth';
function ViewTransactions(params) {

    let [transactions, setTransactions] = useState([]);

    let [formData, setFormData] = useState({
        dealerCode:"",
        startDate:"",
        endDate:""
    });

    let [errors, setErrors] = useState({
        startDate:"",
        endDate:""
    });

    function handleChange(e){
    let {name, value} = e.target;

    setFormData({
        ...formData,
        [name]:value
    });

    setErrors({
        ...errors,
        [name]:""
    })

    }

    function validate(){
        let newErrors = {};
        let errorMessage = [];

        if(!formData.startDate){
        newErrors.startDate = "Required!";
        errorMessage.push(newErrors.startDate);
        }

        if(!formData.endDate){
        newErrors.endDate = "Required!";
        errorMessage.push(newErrors.endDate);
        }

        if(errorMessage > 0){
            alert(errorMessage.join("\n"));
        }

        return Object.keys(newErrors).length === 0;
    }

    function handleSearch(){

        if(validate()){
            
            if(formData.dealerCode){
                
                API_AUTH().get(`/AmountTransactions/GetDealerTransactions/${formData.dealerCode}/${formData.startDate}/${formData.endDate}`).then(reply=>{
                    if(reply.status === 200){
                        if(reply.data.status === "OK"){
                            setTransactions(reply.data.data);
                        }
                        else{
                            console.log(reply.data.message);
                        }
                    }else{
                        console.log(reply.status);
                        
                    }
                })
                
            }
            else{
                API_AUTH().get(`/AmountTransactions/GetAllTransactions/${formData.startDate}/${formData.endDate}`).then(reply=>{
                    if(reply.status === 200){
                        if(reply.data.status === "OK"){
                            setTransactions(reply.data.data);
                        }
                        else{
                            console.log(reply.data.message);
                        }
                    }else{
                        console.log(reply.status);
                        
                    }
                })
            }
            
        }


    }
    
    return(
        <>
            <div className="row my-1">
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                    <select
                        className="form-control form-control-sm"
                        value={formData.dealerCode}
                        name="dealerCode"
                        onChange={(e)=> setFormData({
                            ...formData,
                            [e.target.name]: e.target.value
                        })}
                    >
                        <option disabled={formData.dealerCode? true : false}>Select Dealer Code</option>

                        {
                            params.dealers.map((e, i)=>(
                                <option>{e.dealerCode}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                    <input type="date" name="startDate" value={formData.startDate} onChange={handleChange} className={`form-control form-control-sm ${errors.startDate? "is-invalid":""}`}  />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                <input type="date" name="endDate" value={formData.endDate} onChange={handleChange} className={`form-control form-control-sm ${errors.endDate? "is-invalid":""}`}  />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                <button className="btn btn-primary btn-sm" onClick={handleSearch}>Search</button>
                </div>
            </div>
            
            <hr/>
            
            <table className="table table-bordered table-striped">
            <thead>
                <th>Dealer Code</th>
                <th>Date</th>
                <th>voucher No</th>
                <th>Type</th>
                <th></th>
            </thead>
            <tbody>
                {transactions.map((e, i)=>(
                    <>
                    <tr><td>{e.dealerCode}</td><td>{e.tDate.split('T')[0]}</td><td>{e.voucherNo}</td><td>{e.tType}</td><td><button className="btn btn-primary btn-sm" id={i} data-bs-toggle="collapse" data-bs-target={"#C"+i}>View More</button></td></tr>
                    <td colSpan={4} ><div className="collapse" id={"C"+i}>
                        <div><span style={{fontWeight:"bold"}}>Details : </span> {e.details}</div><span style={{fontWeight:"bold"}}>Remarks: </span>{e.remarks}</div></td>
                        </>
                ))}
            </tbody>
            </table>
        </>
    )
}
export default ViewTransactions;

