import axios from "axios";
import { useState } from "react";

function NewTicket(){

    let [formData, setFormData] = useState({
        dealerCode:window.uName,
        amount:"",
        details:""
    });

    let [errors, setErrors] = useState({
        amount:"",
        details:""
    })

    function Validation(){
        let newError = {};
        let errorMessage = [];

        if(!formData.details){
            newError.details = "Details required!";
            errorMessage.push("Details required!");
        }

        setErrors(newError);

        if(errorMessage.length > 0){
            alert(errorMessage.join("\n"));
        }

        return Object.keys(newError).length === 0;

    }

    function handleChange(e){
        let {name, value} = e.target;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]:""
        });
    }

    // handle number only input in amount
    const handleKeyPress = (e) => {
        if (!/[0-9.]/.test(e.key)) {
        e.preventDefault();
        }
    };

   async function submit(){
        if(Validation()){
            
            await axios.post(process.env.REACT_APP_BASE_URL+"/DealerRequest/CreateNewTicket", formData,{
                headers:{
                    Authorization: `Bearer ${window.token}` 
                }
            }).then(reply=>{
                if(reply.status === 200){
                    if(reply.data.status === "OK"){
                        alert("Query Send!");
                    }
                    else{
                        alert(reply.data.message);
                    }
                }
                else{
                    alert(reply.status);
                }
            }
            )
            
        }
    }

    return(
        <>
        <div className="container-fluid row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-2 ">
            <div className="row justify-content-center" style={{marginTop:"100px"}}>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 custom-div p-2" style={{backgroundColor:'#ccccff'}}>
                    <div className="row mt-3">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <label>Amount Details <span style={{color:"gray"}}>(If any)</span></label>
                            <div className="d-flex">
                            <input className={`form-control form-control-sm ${errors.amount? "is-invalid" : ""}`}
                            onChange={handleChange}
                            value={formData.amount}
                            name="amount"
                            placeholder="0.00"
                            onKeyPress={handleKeyPress} /> 
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label>Your Query (Please give proper details)</label>
                            <textarea rows="5" className={`form-control form-control-sm ${errors.details? "is-invalid" : ""}`}
                            onChange={handleChange}
                            value={formData.details}
                            name="details" />
                            <div className="error">{errors.details}</div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <button className="btn custom-btn" onClick={submit}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" style={{marginTop:"50px", gap:"10px", justifyContent:"center"}}>

            
            </div>
            </div>
        </div>
        </>
    )
}

export default NewTicket;