import React, { useEffect, useState } from "react";
import { setLogin } from "../action";
import { connect } from "react-redux";
import logo from "../images/logoGIF.gif";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import viewImg from '../images/view.png';
import hideImg from '../images/hidden.png';

function Login({ commonData, setLogin }) {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let [showProgress,setProgress]=useState('none');
  const [errors, setErrors] = useState({ username: "", password: "" });
  let nav = useNavigate();
  let [ck, addCk] = useCookies("");
  let [showPass, setShowPass] = useState(false);
  
  
  const validate = () => {
    let isValid = true;
    const newErrors = { username: "", password: "" };

    if (!username) {
      newErrors.username = "Username is required";
      isValid = false;
    } 

    if (!password) {
      newErrors.password = "Password is required";
      isValid = false;
    } 
    // else if (password.length < 6) {
    //   newErrors.password = "Password must be at least 6 characters";
    //   isValid = false;
    // }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validate()) {
      let uname = document.getElementById("idUsername");
      let upass = document.getElementById("idPassword");

      let obj = {
        login : uname.value,
        Password : upass.value
      };

      console.log(obj, 'obj');
      

      let json = JSON.stringify(obj);
      let config = {headers: {'Content-Type' : 'application/json', 'Accept' : '*/*'}};
      setProgress('block');
      axios.post(process.env.REACT_APP_BASE_URL+"/Login/ValidateLogin", json, config).then(reply =>{
        console.log(reply, 'reply');
        
        if(reply.status == 200){
          console.log(reply.status,'status');
          
          if(reply.data.status == "OK"){
            console.log(reply.data.data,'res');
            setProgress('none');
            if(document.getElementById("idRemember").checked){
              let date = new Date();
              date.setDate(date.getDate()+15);
              addCk("UNAME",username,{expires:date});
            }

            window.token = reply.data.data.token;
            window.utype = reply.data.data.utype;
            window.uName = uname.value;

            setLogin("Y");
            if(window.utype == "Admin"){
              nav("/adminDashboard")
            }
            else if (window.utype == "Dealer") {
              nav("/DealerDashboard");
            }
            
            
          }
          else{
            setProgress('none');
            alert("Invalid username/password or you are not registered!");
          } 
        }
        
      }).catch(err => {
        if(err){
          if(err.code == "ERR_NETWORK"){
            if(navigator.onLine){
            console.log("Error from Login: ",err);
            setProgress('none');
            alert("Server Problem, Please try again after some time.");
          }
          else{
            setProgress('none');
            alert("Check your Internet Connection!");
          }
         
        }
      }
      });

    }
  };

  function showHideEye(){
    setShowPass(!showPass);
  }
  
  useEffect(()=>{

    if(ck.UNAME){
      setUsername(ck.UNAME);
    }
  },[])

  return (
    <>
      <div className="d-flex row p-1 my-5 mx-1 justify-content-center align-items-center min-height-70vh">
        <div className="custom-div col-xl-10 col-lg-10 col-md-10 col-sm-12">
          <div className="row">
            {/* logo panel */}
            <div className="min-height-70vh col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex  align-items-center justify-content-center">
              <div className="row justify-content-center">
                <div className="col align-items-center justify-content-center">
                  <img src={logo} style={{ width: "200px" }} />
                </div>
              </div>
            </div>
            {/* ------ logo panel ------ */}

            <div
              style={{ backgroundColor: "#009ee3" }}
              className="custom-div d-flex p-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 pb-3 align-items-center justify-content-center "
            >
              <form>
                <div className="row">
                  <div style={{ width: "" }} className="col">
                    <input
                      id="idUsername"
                      style={{ fontFamily: "sans-serif", width:"12rem" }}
                      placeholder="Login"
                      type="text"
                      className="form-control form-control-sm"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    {errors.username && (
                      <small
                        className="text-danger"
                        style={{ color: "#e81c24", fontFamily: "sans-serif" }}
                      >
                        {errors.username}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col">
                   <div className="d-flex  position-relative">
                    <input
                      id="idPassword"
                      style={{ fontFamily: "sans-serif", width:"12rem", marginRight:"5px", paddingRight: "2.5rem" }}
                      placeholder="Password"
                      type={!showPass ? "password" : "text"}
                      className="form-control form-control-sm"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <img src={showPass? viewImg : hideImg} onClick={showHideEye}
                          style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          width: "20px", 
                        
                          cursor: "pointer"
                          }}/>
                    </div>
                    {errors.password && (
                      <small
                        className="text-danger"
                        style={{ color: "#e81c24", fontFamily: "sans-serif" }}
                      >
                        {errors.password}
                      </small>
                    )}
                  </div>
                </div>

                <div>
                  <input
                  id="idRemember"
                    style={{ fontFamily: "Helvetica Neue" }}
                    type="checkbox"
                    className="my-3"
                  />{" "}
                  Remember me <br />
                  <div className="d-flex g-3">
                  <button className="cssbuttons-io-button" onClick={handleSubmit}>
                    LogIn
                    <div className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          fill="currentColor"
                        ></path>
                      </svg>                      
                    </div>                    
                  </button>
                  <div id="divprg" class="spinner-border mt-2 text-light" style={{display:showProgress, marginLeft:"1rem"}}></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
let connectToStore = (value) => ({ commonData: value });
let dispatchToStore = (dispatch) => ({
  setLogin: (value) => dispatch(setLogin(value))
});
export default connect(connectToStore, dispatchToStore)(Login);
