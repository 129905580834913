import { useEffect } from "react";

function DealerDashboard() {

    
return(
    <>
    <h1 style={{margin:"100px"}} >Dealer Dashboard</h1>
    </>
)
}

export default DealerDashboard;