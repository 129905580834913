import axios from "axios";
import { useState } from "react";
import ItemReturnReply from "./returnReplyTable";

function ReturnItem() {
    const [itemdata, setItemData] = useState({
        voucherNo: "",
        productCode: "",
        productName: "",
        material: "",
        vehicleType: "",
        batchNo: "",
        remark: "",
        itemReturnPhotos: []
    });

    const [errors, setErrors] = useState({});

    const ValidateData = () => {
        let errors = {};

        if (!itemdata.voucherNo) {
            errors.voucherNo = "Voucher Number is required";
        }

        if (!itemdata.productCode) {
            errors.productCode = "Product Code is required";
        }

        if (!itemdata.productName) {
            errors.productName = "Product Name is required";
        }

        if (!itemdata.material) {
            errors.material = "Material is required";
        }

        if (!itemdata.vehicleType) {
            errors.vehicleType = "Vehicle Type is required";
        }

        if (!itemdata.batchNo) {
            errors.batchNo = "Batch Number is required";
        }

        
        if(!pic1)
        {
            errors.pic1= "Complete Product Photo is Required"
        }

        if(!pic2)
        {
            errors.pic2= "Photo of Brand Name is Required"
        }

        if(!pic3)
        {
            errors.pic3= "Product Details Photo is Required"
        }
        if(!pic4)
        {
            errors.pic4= "Damage Photo Part 1 is Required"
        }

        if(!pic5)
        {
            errors.pic5="Damage Photo Part 2 is Required"
        }


        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    let [pic1, setPic1] = useState();
    let [pic2, setPic2] = useState();
    let [pic3, setPic3] = useState();
    let [pic4, setPic4] = useState();
    let [pic5, setPic5] = useState();

    

    function handleImageChange(e, setPic) {
        let file = e.target.files[0];
        // let {name,value}=e.target;
        if (file) {
            let reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = function (e) {
                let base64 = btoa(e.target.result);
                setPic(base64);
                setItemData((prevData) => ({
                    ...prevData,
                    itemReturnPhotos: [...prevData.itemReturnPhotos, base64]
                }));
                //setErrors({...errors, [name]:""});
            };
        }
    }

    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setItemData({
            ...itemdata,
            [name]: value,
        });

        setErrors({...errors, [name]:""});
        //console.log(itemdata);
    };


    const handleSave = async (e) => {
        e.preventDefault();
        
        let photoArr =[];

        for(let i = 0; i< itemdata.itemReturnPhotos.length; i++){
            let photoSet = {
                photo: itemdata.itemReturnPhotos[i],
                discription: "S"
            }

            photoArr.push(photoSet);

        }

        let dataToSend = {
            ...itemdata,
            itemReturnPhotos: photoArr
        }

       
        
        if (ValidateData()) {
            // Save logic here
           console.log(dataToSend);
            axios.post(process.env.REACT_APP_BASE_URL + "/ItemReturn/SaveRequests", dataToSend,
                {
                    headers: {Authorization: `Bearer ${window.token}`}
                }
            ).then(reply=>{
                if(reply.status==200)
                    {
                        if(reply.data.status=="OK")
                            {
                                alert("Request Submitted Successfully");
                            }
                        }
                    });
                }

        setItemData({
            voucherNo: "",
            productCode: "",
            productName: "",
            material: "",
            vehicleType: "",
            batchNo: "",
            remark: "",
            itemReturnPhotos: [] 
        });


        // pic1.current.value = null;
        // pic2.current.value = null;
        // pic3.current.value = null;
        // pic4.current.value = null;
        // pic5.current.value = null;

        // setPic1(null);
        // setPic2(null);
        // setPic3(null);
        // setPic4(null);
        // setPic5(null);
    };


    return (
        <>
            <div className="row mx-2 p-5 my-2 align-items-center justify-content-center">
                <div className="custom-div col-xl-8 col-lg-8 col-md-10 col-sm-12 p-3 align-items-center justify-content-center mt-4" style={{backgroundColor:'#ccddff'}}>

                    {/** Main Heading ***/}
                    <div className="col">
                        <div className="row">
                            <div className="bg-primary text-light" style={{ borderRadius: "5px" }}>
                                <h5 className=""> Return Item Details </h5>
                            </div>
                            {/** Batch & Voucher Number ***/}
                            <div className="col">
                                <div className="row mt-2">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <label>Batch Number</label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-sm" 
                                            name="batchNo"
                                            value={itemdata.batchNo}
                                            onChange={handleInputChange}
                                        />
                                        {errors.batchNo && (<p className="text-danger">{errors.batchNo}</p>)}
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <label>Voucher Number</label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-sm"
                                            name="voucherNo"
                                            value={itemdata.voucherNo}
                                            onChange={handleInputChange}
                                        />
                                        {errors.voucherNo && (<p className="text-danger">{errors.voucherNo}</p>)}
                                    </div>
                                </div>

                                {/** Product ***/}
                                <div className="row mt-2">
                                    <div className="col-xl-4 col-lg-4 col-md-8 col-sm-12">
                                        <label>Product Code</label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-sm"
                                            name="productCode"
                                            value={itemdata.productCode}
                                            onChange={handleInputChange}
                                        />
                                        {errors.productCode && (<p className="text-danger">{errors.productCode}</p>)}
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                        <label>Product Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control form-control-sm"
                                            name="productName"
                                            value={itemdata.productName}
                                            onChange={handleInputChange}
                                        />
                                        {errors.productName && (<p className="text-danger">{errors.productName}</p>)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/** Material & Vehicle Type **/}
                        <div className="row mt-2">
                            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                <label> Material </label>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm"
                                    name="material"
                                    value={itemdata.material}
                                    onChange={handleInputChange}
                                />
                                {errors.material && (<p className="text-danger">{errors.material}</p>)}
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                <label> Vehicle Type </label>
                                <input 
                                    type="text" 
                                    className="form-control form-control-sm"
                                    name="vehicleType"
                                    value={itemdata.vehicleType}
                                    onChange={handleInputChange}
                                />
                                {errors.vehicleType && (<p className="text-danger">{errors.vehicleType}</p>)}
                            </div>
                        </div>

                        {/** Remark ***/}
                        <div className="row mt-2">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <label>Remark</label>
                                <br />
                                <textarea 
                                    className="form-control form-control-sm" 
                                    name="remark"
                                    value={itemdata.remark}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        {/** Photos **/}
                        <div className="row mt-2">
                            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                <label>Photos</label>
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Photo of Complete Product</label>
                                    <input 
                                        type="file" 
                                        onChange={(e) => handleImageChange(e, setPic1)} 
                                        id="pcomplete" 
                                        name="photo1" 
                                        accept="image/*" 
                                        className="form-control form-control-sm-sm" 
                                    />
                                    {errors.pic1 && (<p className="text-danger">{errors.pic1}</p>)}
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                        {pic1 && <img src={"data:image/*;base64," + pic1} style={{ width: "180px", height:"180px", padding:"5px" }} />}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Photo with Brand Name</label>
                                    <input 
                                        type="file" 
                                        onChange={(e) => handleImageChange(e, setPic2)} 
                                        id="pBName" 
                                        name="photo2" 
                                        accept="image/*" 
                                        className="form-control form-control-sm-sm" 
                                    />
                                    {errors.pic2 && (<p className="text-danger">{errors.pic2}</p>)}
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                        {pic2 && <img src={"data:image/*;base64," + pic2} style={{ width: "180px", height:"180px", padding:"5px" }} />}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Photo with Product Details</label>
                                    <input 
                                        type="file" 
                                        onChange={(e) => handleImageChange(e, setPic3)} 
                                        id="pTDetail" 
                                        name="photo3" 
                                        accept="image/*" 
                                        className="form-control form-control-sm-sm" 
                                    />
                                    {errors.pic3 && (<p className="text-danger">{errors.pic3}</p>)}
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                        {pic3 && <img src={"data:image/*;base64," + pic3} style={{ width: "180px", height:"180px", padding:"5px" }} />}
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Damage Part Photo 1</label>
                                    <input 
                                        type="file" 
                                        onChange={(e) => handleImageChange(e, setPic4)} 
                                        id="pDArea1" 
                                        name="photo4" 
                                        accept="image/*" 
                                        className="form-control form-control-sm-sm" 
                                    />
                                    {errors.pic4 && (<p className="text-danger">{errors.pic4}</p>)}
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                        {pic4 && <img src={"data:image/*;base64," + pic4} style={{ width: "180px", height:"180px", padding:"5px" }} />}
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <label>Damage Part Photo 2</label>
                                    <input 
                                        type="file" 
                                        onChange={(e) => handleImageChange(e, setPic5)} 
                                        id="pDArea2" 
                                        name="photo5" 
                                        accept="image/*" 
                                        className="form-control form-control-sm-sm" 
                                    />
                                    {errors.pic5 && (<p className="text-danger">{errors.pic5}</p>)}
                                    <div className="form-control form-control-sm mt-2" style={{ width: "200px", height: "200px" }}>
                                        {pic5 && <img src={"data:image/*;base64," + pic5} style={{ width: "180px", height:"180px", padding:"5px" }} />}
                                    </div>
                                </div>
                            </div>
                            {/* {errors.itemReturnPhotos && (<p className="text-danger">{errors.itemReturnPhotos}</p>)} */}
                        </div>

                        {/** Save Button ***/}
                        <div className="row mt-3 justify-content-left">
                            <div className="col-3">
                                <button className="btn custom-button" onClick={handleSave}>Save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ItemReturnReply/>
        </>
    );
}

export default ReturnItem; 