let myData = {isLogin : "N",total: "0.00", basket: []};

export function Reducer(state = myData, action){

    if (action.type == "setLogin") {
        return {...state, isLogin : action.value}; 
    }

    else if(action.type == "addInBasket"){

        let cart = [...state.basket];
        let exist=false;
        for(let i = 0; i < cart.length; i++){
            if(action.pId == cart[i].pId){
                cart[i].qty=parseInt(cart[i].qty)+parseInt(action.qty);
                exist=true;
                break;
            }            
        }
        if(!exist)
        {
            cart=[...state.basket,{pId: action.pId, price:action.price, qty: action.qty}];
        }
        
        return {...state, basket: cart};
    }

    else if(action.type == "addInTotal")
    {
        let t=0;
        for(let i=0;i<state.basket.length;i++)
        {
            t=t+parseInt(state.basket[i].price*state.basket[i].qty);
        }
        return {...state,total:t};
    }

    else if(action.type == "removeFromBasket"){

        let a = [...state.basket];
        let idx = -1;
        for(let i = 0; i < a.length; i++){
            if(action.pId == a[i].pId) idx = i;
        }
        if(idx >= 0){
            a.splice(idx, 1);
        }

        return {...state, basket: a};
    }

    else if(action.type == "clearBasket"){

        let cart = [...state.basket];

        cart.splice(0, cart.length);

        return{...state, basket: cart};
    }

    else {
        return state;
    }
}