
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Photos from './productPhoto';
import { connect } from 'react-redux';
import edit from '../images/edit.png';
import { div } from 'react-scroll';

function Product({commonData}) {

  //*************** API Function*/
  const [categoryId, setCategoryId] = useState([]);
  const [button, setButton] = useState("Save");
  const [popUp, setPopUp] = useState(false);

  let nav = useNavigate();

  //*************** API Authentication*/
  function API_AUTH() {

    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: { Authorization: `Bearer ${window.token}`},
    });
  }

  // Form Validation
  let [formValues, setFormValues] = useState({
    pid: 0,
    cid: '',
    productCode: '',
    productName: '',
    size: '',
    material: '',
    width: '',
    loadingCapacity: '',
    plyRate: '',
    vehicleType: '',
    minOrder: '',
    warrentyYears: '',
    warrentyKm: '',
    price: '',
    gstRate: '',
    hsn: '',
    otherDetails: '',

  });

const [errors, setErrors] = useState({
    pid: 0,
    cid: '',
    productCode: '',
    productName: '',
    size: '',
    material: '',
    width: '',
    loadingCapacity: '',
    plyRate: '',
    vehicleType: '',
    minOrder: '',
    warrentyYears: '',
    warrentyKm: '',
    price: '',
    gstRate: '',
    hsn: '',
    otherDetails: '',
  });


  function ValidateForm() {
    let newError = {};
    let errorMessage = [];
    
    if (!formValues.cid) {
      newError.cid = "Select Category!";
      errorMessage.push(newError.cid);
    }
    if (!formValues.productCode) {
      newError.productCode = "Product code is required!";
      errorMessage.push(newError.productCode);
    }
    if (!formValues.productName) {
      newError.productName = "Product name is required!";
      errorMessage.push(newError.productName);
    }
    if (!formValues.size) {
      newError.size = "Size is required!";
      errorMessage.push(newError.size);
    }
    if (!formValues.material) {
      newError.material = "Material is required!";
      errorMessage.push(newError.material);
    }
    if (!formValues.width) {
      newError.width = "Width is required!";
      errorMessage.push(newError.width);
    }
    if (!formValues.loadingCapacity) {
      newError.loadingCapacity = "Loading Capacity is required!";
      errorMessage.push(newError.loadingCapacity);
    }
    if (!formValues.plyRate) {
      newError.plyRate = "Ply rate is required!";
      errorMessage.push(newError.plyRate);
    }
    if (!formValues.vehicleType) {
      newError.vehicleType = "Vehicle type is required!";
      errorMessage.push(newError.vehicleType);
    }
    if (!formValues.minOrder) {
      newError.minOrder = "Minimum order is required!";
      errorMessage.push(newError.minOrder);
    }
    if (!formValues.warrentyYears) {
      newError.warrentyYears = "Warranty years are required!";
      errorMessage.push(newError.warrentyYears);
    }
    if (!formValues.warrentyKm) {
      newError.warrentyKm = "Warranty Kilometer is required!";
      errorMessage.push(newError.warrentyKm);
    }
    if (!formValues.price) {
      newError.price = "Price is required!";
      errorMessage.push(newError.price);
    }
    if (!formValues.gstRate) {
      newError.gstRate = "GST rate is required!";
      errorMessage.push(newError.gstRate);
    }
    if (!formValues.hsn) {
      newError.hsn = "HSN is required!";
      errorMessage.push(newError.hsn);
    }

    setErrors(newError);

    if(errorMessage.length > 0){
      alert(errorMessage.join("\n"));
    }

    return Object.keys(newError).length === 0;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };


  function ClearFormValues() {
    setFormValues({
      cid: "select",   
      productName: '',
      size: '',
      material: '',
      width: '',
      loadingCapacity: '',
      plyRate: '',
      vehicleType: '',
      minOrder: '',
      warrentyYears: '',
      warrentyKm: '',
      price: '',
      gstRate: '',
      hsn: '',
      otherDetails: '',

    });
  }



  //*************** Get Categories ******************/

  function getCategory() {

    API_AUTH().get("/Categories/AllCategories").then(reply => {
      if (reply.status == 200) {
        if (reply.data.status) {
          setCategoryId(reply.data.data);
        }
        else {
          console.log("error:" + reply.data.message);
        }
      }
      else {
        console.log(reply);
      }
    })
  }

  // Show Product By Category ID in table
  let [products , setProducts] = useState([]);

  function getProductByCid(cid){
    API_AUTH().get("/Products/ProductByCategoryId/"+cid).then(reply=>{
      if(reply.status == 200){
          if(reply.data.status == "OK"){
            setProducts(reply.data.data);
          }
          else{
            alert(reply.data.message);
          }
      }else{
        alert("Some error while loading products");
      }
    })
  }

  //.............................Get Selected categoryId............................./
  function getSelectCategory(e) {
    const { name, value } = e.target;

    getProductByCid(e.target.value);

      setFormValues({
        ...formValues,
        [name]: value,
      });
  
      setErrors({
        ...errors,
        [name]: "",
      });
  }

  // Get Category By ID
  let [catName, setCatName] = useState();
  //....................................GET Product data....................../
  async function productDetails() {
    if (formValues.productCode == "") {}
    else {
      API_AUTH().get("/Products/ProductByProductCode/"+formValues.productCode).then((reply) => {
        if (reply.status == 200) {
          if (reply.data.status == "OK") {
            if (reply.data.data) {

              setButton("Update");
              
              // set form values
              setFormValues({
                pid:reply.data.data.pid,
                cid: reply.data.data.cId,
                productCode: reply.data.data.productCode,
                productName: reply.data.data.productName,
                size: reply.data.data.size,
                material: reply.data.data.material,
                width: reply.data.data.width,
                loadingCapacity: reply.data.data.loadingCapacity,
                plyRate: reply.data.data.plyRate,
                vehicleType: reply.data.data.vehicleType,
                minOrder: reply.data.data.minOrder,
                warrentyYears: reply.data.data.warrentyYears,
                warrentyKm: reply.data.data.warrentyKm,
                price: reply.data.data.price,
                gstRate: reply.data.data.gstRate,
                hsn: reply.data.data.hsn,
                otherDetails: reply.data.data.otherDetails
              });
            }
            else {
              setButton("Save");

              // setFormValues({
              //   productName: '',
              //   size: '',
              //   material: '',
              //   width: '',
              //   loadingCapacity: '',
              //   plyRate: '',
              //   vehicleType: '',
              //   minOrder: '',
              //   warrentyYears: '',
              //   warrentyKm: '',
              //   price: '',
              //   gstRate: '',
              //   hsn: '',
              //   otherDetails: '',
              // });
            }
          }
          else {
            console.log(reply.data.status);
          }
        }
        else {
          console.log(reply);
        }
      });
    }
  }


  // handle Number Only input
  const handleKeyPress = (e) => {
    const { name, value } = e.target;
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    const { name, value } = e.target;
    const paste = (e.clipboardData || window.clipboardData).getData("text");
    if (!/^\d+$/.test(paste)) {
      e.preventDefault();
    }
  };
let prodCode = "";

  //save data
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // window.pcode = "p45";
    // setPopUp(true);

    let isValid = ValidateForm();
    if (isValid) {
      prodCode = formValues.productCode;
      window.pcode = formValues.productCode;

      let dataToSend = {  
        ...formValues,
        cid: parseInt(formValues.cid, 10),
        minOrder: parseInt(formValues.minOrder, 10),
        warrentyYears: parseInt(formValues.warrentyYears, 10),
        warrentyKm: parseInt(formValues.warrentyKm, 10),
        price: parseInt(formValues.price, 10),
        gstRate: parseInt(formValues.gstRate,10)

      }
      // console.log(dataToSend);
      try { 

        // send data to server
        await axios.post(process.env.REACT_APP_BASE_URL + "/Products/SaveProduct", dataToSend, {
          headers: {
            Authorization: `Bearer ${window.token}`,
          },
        }
        )
          .then((reply) => {
            if (reply.status == 200) {
              if (reply.data.status == "OK") {
                
                if(window.confirm("Product Saved! Add Photos ?")){
               
                  setPopUp(true);
                  
                  // ClearFormValues();
                }
              }
              else {
                alert("Product already exists!");
              }
            }
            else {
              console.log(reply);
              alert(reply);
            }
          });
      } catch (error) {
        if (error.inner && error.inner.length > 0) {
          const newError = {};

          error.inner.forEach((err) => {
            newError[err.path] = err.message;
          });
          setErrors(newError);
        } else {
          console.log("Validation Error", error);
        }
      }
    }
  };

  // Update Product
  async function updateProduct() {
    let dataToSend = {  
      ...formValues,
      cid: parseInt(formValues.cid, 10),
      minOrder: parseInt(formValues.minOrder, 10),
      warrentyYears: parseInt(formValues.warrentyYears, 10),
      warrentyKm: parseInt(formValues.warrentyKm, 10),
      price: parseInt(formValues.price, 10),
      gstRate: parseInt(formValues.gstRate,10)

    }
    
    // console.log(formValues);
    await axios.post(process.env.REACT_APP_BASE_URL + "/Products/UpdateProduct", formValues, {
      headers: { Authorization: `Bearer ${window.token}`}
    }).then(reply => {
      if (reply.status == 200) {
        if (reply.data.status == "OK") {
          alert(reply.data.message);

          ClearFormValues();
          setButton("Save")
        }
        else {
          console.log(reply.data.status);
        }
      } else {
        console.log(reply.status);
      }
    }).catch(err => {
      if (err) {
        console.log(err);
      }
    })
  }

  // to close the upload image pop up
  function closePopUp(value){
    setPopUp(value);
  }

  // update Product details by Edit Button of Table
  function editProduct(pcode){
    formValues.productCode = pcode;
    productDetails();
  }

  useEffect(() => {

    if(commonData.isLogin == "N"){
      nav("/");
    }
    else{
      getCategory();
    
    }
  }, []);

  return (
    <div name='my-section'>
      {popUp && <Photos pcode={formValues.productCode} toClose={closePopUp} />}

      <div id='idMainDiv' className="row mx-2 text-dark" style={{marginTop:"100px",justifyContent:"center"}}>
        <div className="custom-div col-xl-8 col-lg-8 col-md-10 col-sm-12">
          <div className="col">
            <div className="row p-3"  style={{backgroundColor:'#ccddff'}}>
              <div style={{backgroundColor:"#bbccff", color:"black", borderRadius:"10px",padding:'10px',textAlign:'center'}}>
                <h3>Product Details</h3>
              </div>
              <div className="row mt-2">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                  <label>Category Name</label>

                  <select
                    value={formValues.cid}
                    name="cid"
                    onChange={getSelectCategory}
                    className={`form-control ${errors.cid? "is-invalid" : ""}`}

                  >
                    <option disabled={formValues.cid? true : false}>Select</option>

                    {categoryId.map((e, i)=>(
                      <option value={e.cid} selected={e.categoryName == formValues.cid}>{e.categoryName}</option>
                    ))}

                  </select>
                  <div className="error">{errors.cid}</div>
                </div>

                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style={{justifyContent:"flex-end", display:"flex",height:"40px"}}>
          <button onClick={()=>setPopUp(!popUp)} style={{display:`${formValues.productCode? "block" : "none"}`}} className='btn btn-primary'>+Add Photo</button>
        </div>
              </div>
              <div className="row mt-2" >
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12" >
                  <label>Product Code</label>
                 
                  <input
                  value={formValues.productCode}
                  name='productCode'
                  onBlur={productDetails}
                  onChange={handleChange}
                  className={`form-control ${errors.productCode? "is-invalid":""}`}
                  />
                  <div className="error">{errors.productCode}</div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Product Name</label>
                  <input
                    id="idproductName"
                    value={formValues.productName}
                    onChange={handleChange}
                    name="productName"
                    type="text"
                    className={`form-control ${errors.productName ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.productName}</div>
                </div>

              </div>

              <div className="row mt-2">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Width</label>
                  <input
                    id="idwidth"
                    value={formValues.width}
                    onChange={handleChange}
                    name="width"
                    type="text"
                    className={`form-control ${errors.width ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.width}</div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Size</label>
                  <input
                    id="idSize"
                    value={formValues.size}
                    onChange={handleChange}
                    name="size"
                    type="text"
                    className={`form-control ${errors.size ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.size}</div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Vehicle Type</label>
                  <input
                    id="idvehicletype"
                    value={formValues.vehicleType}
               
                    onChange={handleChange}
                    name="vehicleType"
                    type="text"
                    className={`form-control ${errors.vehicleType ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.vehicleType}</div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Material</label>
                  <input
                    id="idmaterial"
                    value={formValues.material}
                   
                    onChange={handleChange}
                    name="material"
                    type="text"
                    className={`form-control ${errors.material ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.material}</div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>PLY Rate</label>
                  <input
                    id="idplyrate"
                    value={formValues.plyRate}
                   
                    onChange={handleChange}
                    name="plyRate"
                    type="text"
                    className={`form-control ${errors.plyRate ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.plyRate}</div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Loading Capacity</label>
                  <input
                    id="idloadingCapacity"
                    value={formValues.loadingCapacity}
                    onKeyPress={handleKeyPress}
                  onPaste={handlePaste}
                    onChange={handleChange}
                    name="loadingCapacity"
                    type="text"
                    className={`form-control ${errors.loadingCapacity ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.loadingCapacity}</div>
                </div>

              </div>

              <div className="row mt-2">

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Minimum Order</label>
                  <input
                    id="idminorder"
                    value={formValues.minOrder}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={handleChange}
                    name="minOrder"
                    type="text"
                    className={`form-control ${errors.minOrder ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.minOrder}</div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Warrenty Years</label>
                  <input
                    id="idwarrentyyears"
                    value={formValues.warrentyYears}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={handleChange}
                    name="warrentyYears"
                    type="text"
                    className={`form-control ${errors.warrentyYears ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.warrentyYears}</div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Warrenty Kilometer</label>
                  <input
                    id="idwarrentykilometer"
                    value={formValues.warrentyKm}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={handleChange}
                    name="warrentyKm"
                    type="text"
                    className={`form-control ${errors.warrentyKm ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.warrentyKm}</div>
                </div>
              </div>


              <div className="row mt-2">


                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>Price</label>
                  <input
                    id="idprice"
                    value={formValues.price}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={handleChange}
                    name="price"
                    type="text"
                    className={`form-control ${errors.price ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.price}</div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>GST Rate</label>
                  <input
                    id="idgstrate"
                    value={formValues.gstRate}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    onChange={handleChange}
                    name="gstRate"
                    type="text"
                    className={`form-control ${errors.gstRate ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.gstRate}</div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <label>HSN</label>
                  <input
                    id="idhsn"
                    value={formValues.hsn}
                 
                    onChange={handleChange}
                    name="hsn"
                    type="text"
                    className={`form-control ${errors.hsn ? "is-invalid" : ""

                      }`}
                  />
                  <div className="error">{errors.hsn}</div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <label>Other Details</label> <br />
                  <textarea
                    style={{ width: "100%", height: "70%" }}
                    id="idotherdetails"
                    value={formValues.otherDetails}
                    onChange={handleChange}
                    name="otherDetails"
                    className={`form-control ${errors.otherDetails ? "is-invalid" : ""
                      }`}
                  />
                  <div className="error">{errors.otherDetails}</div>
                </div>

              </div>
              <br/>
              
              <div className="row mt-2">
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 my-2">
                  <button type="button" onClick={button == "Save"? handleSubmit : updateProduct}
                    className=" btn custom-button">
                    {button}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        

        
        <div style={{marginTop:"50px",backgroundColor:'#ccddff'}} className="custom-div mb-2 col-xl-8 col-lg-8 col-md-10 col-sm-12 pb-3 align-items-center justify-content-center">
        <div style={{ overflow: "auto",height:"30rem"}}>
        <table className="table m-2">
          <thead>
            <tr >
              <th style={{backgroundColor:'#bbccff'}}>Product Code</th>
              <th style={{backgroundColor:'#bbccff'}}>Product Name</th>
              <th style={{backgroundColor:'#bbccff'}}>Vehicle Type</th>
              <th style={{backgroundColor:'#bbccff'}}>Size</th>
              <th style={{backgroundColor:'#bbccff'}}>Width</th>
              <th style={{backgroundColor:'#bbccff'}}>Ply Rate</th>
              <th style={{backgroundColor:'#bbccff'}}>Price</th>
              <th style={{backgroundColor:'#bbccff'}}></th>
            </tr>
          </thead>
          <tbody>
            {products.map((e, i)=>(
              <tr>
                <td>{e.productCode}</td>
                <td>{e.productName}</td>
                <td>{e.vehicleType}</td>
                <td>{e.size}</td>
                <td>{e.width}</td>
                <td>{e.plyRate}</td>
                <td>{e.price}</td>
                <td><Link to="my-section" smooth={true} duration={500}><img src={edit} onClick={()=> editProduct(e.productCode)} style={{width:"30px",cursor:'pointer' }}/></Link></td>
              </tr>
            ))}
          </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  );
}
let connectToStore = (state) => ({ commonData: state });
export default connect(connectToStore) (Product);
