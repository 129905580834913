import { useEffect, useState } from "react";
import API_AUTH from "../../api_auth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function ReplyToQuery(){

    let login = useSelector((state)=>state.login);
    let nav = useNavigate();

    let [nonReply, setNonReply] = useState([]);
    let [replied, setReplied] = useState([]);

    async function getNonReply(){
        await API_AUTH().get("/DealerRequest/GetAllNonReplyTickets").then(reply=>{
            if(reply.status === 200){
                if(reply.data.status === "OK"){
                    setNonReply(reply.data.data);
                }
            }
        })
    }

    async function getReplied(){
        await API_AUTH().get("/DealerRequest/GetAllRepliedTickets").then(reply=>{
            if(reply.status === 200){
                if(reply.data.status === "OK"){
                    setReplied(reply.data.data);
                }
            }
        })
    }

    function sendReply(rid, reqDate, dealerCode, details, amount){

        let rep = document.getElementById("idReply"+rid).value;
        if(rep === ""){
            alert("Reply required");
        }
        else{

        let dataToSend = {
            rId: rid,
            reqDate: reqDate,
            dealerCode: dealerCode,
            details: details,
            amount: amount,
            reply: rep
        }

        axios.post(process.env.REACT_APP_BASE_URL+"/DealerRequest/ReplyTicket",dataToSend, {
            headers:{
                Authorization: `Bearer ${window.token}`
            }
        }).then(reply=>{
            if(reply.status === 200){
                if(reply.data.status === "OK"){
                    alert("Reply Send!");
                }else{
                    alert(reply.data.message);
                }
                
            }else{
                alert(reply.status);
            }
        })
    }
    }

    useEffect(()=>{

        if(login === "N"){
            nav("/");
        }
        else{
            getNonReply();
            getReplied();
        }
    },[])

    return(
        <div className="container-fluid">
     <div className=" row mx-0" style={{marginTop:"100px",gap:"20px", justifyContent:"center" }}>
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 custom-div">
            <div>Non-Reply</div>
            <table className="table table-bordered table-striped">
                <thead>
                    <th>Dealer Code</th>
                    <th>Date</th>
                    <th>Amount</th>
                </thead>
                <tbody>
                {
                    nonReply.map((e,i)=>(
                        <>
                        <tr><td>{e.dealerCode}</td><td>{e.reqDate.split("T")[0]}</td><td>{e.amount}</td>
                        <td><button id={i} data-bs-toggle="collapse" data-bs-target={"#C"+e.rId} className="btn btn-primary" >Reply</button></td></tr>
                     
                        <td colSpan={4} ><div className="collapse" style={{gap:"20px"}} id={"C"+e.rId}>
                        <div><span style={{fontWeight:"bold"}}>Details : </span> {e.details}</div><span style={{fontWeight:"bold"}}>Reply: </span><input className="form-control form-control-sm" id={"idReply"+e.rId}/><button className="custom-button" onClick={()=>sendReply(e.rId,e.reqDate,e.dealerCode,e.details,e.amount)}>Send</button></div></td>
                        </>                    
                        ))
                }
                </tbody>
            </table>
        </div>
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 custom-div">
            <div>Replied</div>
            <table className="table  table-bordered table-striped">
            <thead>
                    <th>Dealer Code</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th></th>
                </thead>
                <tbody>
                {replied.map((e, i)=>(
                    <>
                        <tr><td>{e.dealerCode}</td><td>{e.reqDate.split("T")[0]}</td><td>{e.amount}</td>
                        <td><button id={i} data-bs-toggle="collapse" data-bs-target={"#C"+e.rId} className="btn btn-primary" >View</button></td></tr>
                        <td colSpan={4} ><div className="collapse" id={"C"+e.rId}>
                        <div><span style={{fontWeight:"bold"}}>Details : </span> {e.details}</div><span style={{fontWeight:"bold"}}>Reply: </span>{e.reply}</div></td>
                        </>
                    ))}
                    </tbody>
            </table>
        </div>
     </div>
     </div>
    )
}

export default ReplyToQuery;