import axios from "axios";
import { useEffect, useState } from "react";

function Photos(params) {
  let pcode = params.pcode;

  let [img, setImg] = useState([]);

  let [formValues, setFormValues] = useState({
    productCode: pcode,
    photo: "",
  });

  const [errors, setErrors] = useState({
    photo: "",
  });

  function ValidateForm() {
    let newError = {};

    if (!formValues.photo) {
      newError.photo = "photo is required";
    }

    setErrors(newError);

    return Object.keys(newError).length === 0;
  }
let imgArray = [];
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });

    let images = e.target.files;
    console.log("images : ", images);

    for (let i = 0; i < images.length; i++) {
      let reader = new FileReader();
      reader.readAsBinaryString(images[i]);
      reader.addEventListener("load", async function (e) {
        let fileContent = e.target.result;
        let bs64 = btoa(fileContent);

      imgArray.push(bs64);
      });
    }
    console.log(imgArray);
    setImg(imgArray);
  };


  // SAVE DATA
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let pic = document.getElementById("idphoto");
    
    let isValid = ValidateForm();
    if (isValid) {
      if (pic && pic.files) {

        for(let i = 0; i < pic.files.length; i++){

        // add photos
        let reader = new FileReader();
        reader.readAsBinaryString(pic.files[i]);
        reader.addEventListener("load", async function (e) {
          let fileContent = e.target.result;
          let bs64 = btoa(fileContent);

          // send data to server
          let pc = formValues.productCode;
          let dataToSend = {
            productCode: pc,
            photo: bs64,
          };

          // console.log(dataToSend);

          await axios
            .post(
              process.env.REACT_APP_BASE_URL + "/productPhoto/SavePhoto",
              dataToSend,
              {
                headers: {
                  Authorization: `Bearer ${window.token}`,
                },
              }
            )
            .then((reply) => {
              if (reply.status == 200) {
                if (reply.data.status == "OK") {
                  
                } else {
                  alert(
                    "Something went wrong!\n Message : " + reply.data.message
                  );
                }
              } else {
                alert(reply.status);
              }
            })
            .catch((err) => {
              if (err) {
                alert("Something went wrong!\n" + err);
              }
            });
        });
      }
      alert("Photo Uploaded!");
      } else {
        console.log("dont have image");
      }
    }
  };

  function closePopUp() {
    params.toClose(false);
  }


  return (
    <div id="rootdiv" className="popup-div">
      <div className=" row mx-2 p-5 my-2  align-items-center justify-content-center text-dark justify-content-center align-item-center">
        <div
          style={{ marginTop: "100px" }}
          className="custom-div col-xl-8 col-lg-8 col-md-8 col-sm-12 pb-3 align-items-center justify-content-center"
        >
            <div className="row" style={{backgroundColor:"black", color:"white", borderRadius:"10px"}}>
              <div >
                <h4>Upload Photo</h4>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 mt-3">
               
                <input
                  id="idphoto"
                  value={formValues.photo}
                  onChange={handleChange}
                  name="photo"
                  type="file"
                  accept="image/*"
                  multiple
                  className={`form-control ${errors.photo ? "is-invalid" : ""}`}
                />
                <div className="error">{errors.photo}</div>
              </div>
            </div>

            <div className="row mt-2">
              {img.length > 0 && img.map((e, i)=>(
               <>
               <img src={"data:image/jpeg;base64,"+e} style={{width:"100px"}}/>
               </>
              ))}
            </div>
            <div className="row mt-2">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex">
                <button onClick={handleSubmit} className=" btn custom-button">
                  Upload
                </button>
                <button onClick={closePopUp} className=" btn btn-secondary">
                  Close
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
export default Photos;
