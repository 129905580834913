import { useState } from "react"
import API_AUTH from "../../../api_auth"
import { useDispatch } from "react-redux";
import { addInBasket, addInTotal, removeFromBasket } from "../../../../action";


export function CartRow(params){
    let [products, setProducts] = useState([]);

    let dispatch = useDispatch();

    API_AUTH().get("/Products/ProductById/"+params.pid).then(reply=>{
        if(reply.status == 200){
            if(reply.data.status == "OK"){
                setProducts(reply.data.data);
            }
        }
    })

    function ItemQty(pid,price,qty){

        dispatch(addInBasket(pid,price,qty));
        dispatch(addInTotal())
    }
    
    function removeItem(){
        // console.log(params.pid);
        
    dispatch(removeFromBasket(params.pid));
    }

    return(
        <>
        <div className="card p-2 m-2">
            <div className="row">
                <div className="col">
                    <label>{products.productName}</label><br/>
                    <label>Vehicle Type : </label><span>{products.vehicleType}</span>
                </div>
                <div className="col align-item-center justify-content-center">
                    <label>quantity</label><br/>
                    <div className="d-flex">
                        <button className="btn" onClick={()=>{ItemQty(params.pid,params.price, -1)}}>-</button>
                    <label className="form-control w-25">{params.quantity}</label>
                    <button className="btn" onClick={()=>{ItemQty(params.pid,params.price, 1)}}>+</button>
                    </div>
                </div>
                <div className="col">
                    <label>Price</label><br/>
                    <label>{products.price}</label>
                </div>
                <div className="col">
                    <label>Amount</label><br/>
                    <label>{products.price*params.quantity}</label>
                    
                </div>
                <div className="col" style={{justifyContent:"flex-end", display:"flex"}}>
                    <button onClick={()=>removeItem()} style={{height:"40px"}} className="btn btn-danger">Remove</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default CartRow;