import axios from "axios";
import { useState, useEffect } from "react";

function ItemReturnReply()
{

    let [confirmrequest, setConfirmRequest]= useState([]);
    let [rejectrequest, setRejectRequest]= useState([]);

    ////////////////// Confirmed Return Request //////////////////
function getConfirmRequest()
{
  let AUTH = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Authorization: `Bearer ${window.token}` },
  });
  
  AUTH.get("/ItemReturn/GetAllApproveRequests").then(reply => {
  // console.log(reply.data);
  setConfirmRequest(reply.data.data);
  }).catch(err => {
    // console.log("err form get State: ",err);
    if (err.response.status == 401) {
      // console.log("Unauthorize");
    }
  });
}
///////////////////////////////////////////////////////////////



/////////////////// Rejected Return Request ///////////////////
function getRejectRequest()
{
  let AUTH = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Authorization: `Bearer ${window.token}` },
  });
  
  AUTH.get("/ItemReturn/GetAllRejectRequests").then(reply => {
  // console.log(reply.data);
  setRejectRequest(reply.data.data);
  }).catch(err => {
    // console.log("err form get State: ",err);
    if (err.response.status == 401) {
      // console.log("Unauthorize");
    }
  });
}
///////////////////////////////////////////////////////////////


useEffect(()=>{
    getConfirmRequest();
    getRejectRequest();
},[])

    return(
        <>
        <div className="row align-items-center justify-content-center">
        {/* Row 1 */}
        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-9  justify-content-center  ">
          <div className="row align-items-center justify-content-center ">

            {/* Card 1 Confirm Requests*/}
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center  justify-content-center">
              <div className="card custom-card" style={{ height:"30rem", padding: "10px" }}>
              <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
                  <label>Confirm Requests </label>
                  </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <input 
                    type="search"
                    placeholder="Search Order..."
                    className="form-control form-control-sm"
                  />
                </div>
                </div>
                <hr/>
                <div style={{overflow:"auto"}}> 
                <table className="table table-bordered table-striped">
                  <thead>
                  <tr>
                  <th className="bg-warning">Item Code</th>
                  <th className="bg-warning">Item Name</th>
                  <th className="bg-warning">Approval Date</th>
                  <th className="bg-warning">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {confirmrequest.map((e,i)=>(
                  <tr>
                    <td> {e.productCode} </td>
                    <td> {e.productName} </td>
                    <td> {e.approvalDate} </td>
                    <td> ₹{e.creditAmt} </td>
                  </tr>
                  ))} 
                  </tbody>
                </table>
                </div>
              </div>
            </div>

            {/* card 2 Rejected Requests */}
            <div className="bg-light col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center  justify-content-center">
              <div className="card custom-card" style={{ height:"30rem", padding: "10px" }}>
              <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
                  <label>Rejected Requests </label>
                  </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <input 
                    type="search"
                    placeholder="Search Order..."
                    className="form-control form-control-sm"
                  />
                </div>
                </div>
                <hr/>
                <div style={{overflow:"auto"}}> 
                <table className="table table-bordered table-striped">
                  <thead>
                  <tr>
                  <th className="bg-light">Item Code</th>
                  <th className="bg-light">Item Name</th>
                  <th className="bg-light">Rejected Date</th>
                  <th className="bg-light">Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {rejectrequest.map((e,i)=>(
                  <tr>
                    <td> {e.productCode} </td>
                    <td> {e.productName} </td>
                    <td> {e.approvalDate} </td>
                    <td> ₹{e.creditAmt} </td>
                  </tr>                  
                  ))} 
                  </tbody>
                </table>
                </div>
              </div>
            </div>
            

          </div>
        </div>
        </div>
        </>
    );
}
export default ItemReturnReply; 