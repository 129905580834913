import { useEffect, useState } from "react";
import API_AUTH from "../../api_auth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ViewTransactions from "./viewTransactions";

function SavePaymentDetails() {
  let [dealers, setDealers] = useState([]);
  let [compName, setCompName] = useState();
  let [contPerson, setcontPerson] = useState();

  let loginStatus = useSelector((state) => state.isLogin);

  let nav = useNavigate();

  function getAllDealers() {
    API_AUTH()
      .get("/Dealers/GetAllDealers")
      .then((reply) => {
        if (reply.status === 200) {
          if (reply.data.status === "OK") {
            setDealers(reply.data.data);
          }
        }
      });
  }

  async function dealerDetails(e) {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });

    API_AUTH()
      .get("/Dealers/GetDealer/" + e.target.value)
      .then((reply) => {
        if (reply.status === 200) {
          if (reply.data.status === "OK") {
            if (reply.data.data) {
              setCompName(reply.data.data.companyName);
              setcontPerson(reply.data.data.contactPerson);
            }
          } else {
            console.log(reply.data.status);
          }
        } else {
          console.log(reply);
        }
      });
  }

  let [formData, setFormData] = useState({
    dealerCode: "",
    amount: "",
    details: "",
    tType: "",
    voucherNo: "",
    remarks: ""
  });

  let [errors, setErrors] = useState({
    dealerCode: "",
    amount: "",
    details: "",
    tType: "",
    voucherNo: "",
    remarks: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  function validateForm() {
    let newErrors = {};
    let errorMessage = [];

    if (!formData.dealerCode) {
      newErrors.dealerCode = "Select Dealer Code!";
      errorMessage.push(newErrors.dealerCode);
    }

    if (!formData.tType) {
      newErrors.tType = "Transaction type required!";
      errorMessage.push(newErrors.tType);
    }

    if (!formData.amount) {
      newErrors.amount = "Amount is required!";
      errorMessage.push(newErrors.amount);
    }

    if (!formData.voucherNo) {
      newErrors.voucherNo = "Voucher No is required!";
      errorMessage.push(newErrors.voucherNo);
    }

    if (!formData.details) {
      newErrors.details = "Details are required!";
      errorMessage.push(newErrors.details);
    }

    setErrors(newErrors);

    if(errorMessage.length > 0){
      alert(errorMessage.join("\n"));
    }

    return Object.keys(newErrors).length === 0;
  }

  // handle number only input
  const handleKeyPress = (e) => {
    const { name, value } = e.target;
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    const { name, value } = e.target;
    const paste = (e.clipboardData || window.clipboardData).getData("text");
    if (!/^\d+$/.test(paste)) {
      e.preventDefault();
    }
  };

  async function submit(e) {
    e.preventDefault();

    if (validateForm()) {
      let dataToSend = {
        ...formData,
        amount: parseInt(formData.amount),
        
      };

      await axios.post(process.env.REACT_APP_BASE_URL+"/AmountTransactions/SavePaymentDetails",dataToSend,{
        headers:{
          Authorization: `Bearer ${window.token}`
        }
      }).then(reply=>{
        if(reply.status === 200){
          if(reply.data.status === "OK"){
            alert(reply.data.message);
          }
          else{
            alert(reply.data.message);
          }
        }
        else{
          alert(reply.status);
        }
      })
    }
  }

  useEffect(() => {
    if (loginStatus === "N") {
      nav("/");
    } else {
      getAllDealers();
    }
  }, []);

  return (
   
      <div className="row container-fluid mx-0" style={{justifyContent:"space-evenly"}}>
        <div
          className="col-xl-4 col-lg-4 col-md-4 col-sm-12 custom-div"
          style={{marginTop: "100px", backgroundColor:'#bbddff'}}
        >
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 mt-3">
              <label>Dealer Code</label>
              <select
                className={`form-control form-control-sm ${
                  errors.dealerCode ? "is-invalid" : ""
                }`}
                onChange={dealerDetails}
                value={formData.dealerCode}
                name="dealerCode"
              >
                <option disabled={formData.dealerCode ? true : false}>
                  Select
                </option>
                {dealers.map((e, i) => (
                  <option value={e.dealerCode}>{e.dealerCode}</option>
                ))}
              </select>
              <div className="error">{errors.dealerCode}</div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <label>Company Name</label>
              <input className="form-control form-control-sm" disabled value={compName} />
            </div>
            <div className="col">
              <label>Contact Person</label>
              <input className="form-control form-control-sm" disabled value={contPerson} />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <label>Transaction Type</label>
              <input
                className={`form-control form-control-sm ${errors.tType ? "is-invalid" : ""}`}
                onChange={handleChange}
                value={formData.tType}
                name="tType"
                
              />
              <div className="error">{errors.tType}</div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <label>Amount</label>
              <input
                className={`form-control form-control-sm ${errors.amount ? "is-invalid" : ""}`}
                onChange={handleChange}
                value={formData.amount}
                name="amount"
                onKeyPress={handleKeyPress}
                onPaste={handlePaste}
              />
              <div className="error">{errors.amount}</div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <label>Voucher No</label>
              <input
                className={`form-control form-control-sm ${errors.voucherNo ? "is-invalid" : ""}`}
                onChange={handleChange}
                value={formData.voucherNo}
                name="voucherNo"
                
              />
              <div className="error">{errors.voucherNo}</div>
            </div>
            </div>

          <div className="row mt-3">
            <div className="col">
              <label>Details</label>
              <textarea
                onChange={handleChange}
                value={formData.details}
                name="details"
                className={`form-control form-control-sm ${errors.details ? "is-invalid" : ""}`}
              />
              <div className="error">{errors.details}</div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <label>Remarks</label>
              <textarea
              onChange={handleChange}
                value={formData.remarks}
                name="remarks"
                className="form-control form-control-sm"
              />
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col">
              <button className="custom-button btn" onClick={submit}>
                Save
              </button>
            </div>
          </div>
        </div>
        <div
          className="col-xl-7 col-lg-7 col-md-7 col-sm-12 p-2 custom-div"
          style={{ marginTop: "100px", justifyItems:"center" }}
        >
          <ViewTransactions dealers={dealers} />
        </div>
      </div>
  );
}
export default SavePaymentDetails;
