import axios from "axios";
import { useEffect, useState } from "react";
import ViewMore from "../../Admin/ReturnItem/viewMore";


function App_Del_ReturnItem()
{

let [popUp, setPopUp] = useState(false);
let [itemdata, setitemData] = useState([]);
let [viewmore, setViewMore] = useState([]);
let [confirmrequest, setConfirmRequest]= useState([]);
let [rejectrequest, setRejectRequest]= useState([]);


//////////////////////// PopUp FUnction ///////////////////////
function closePopUp(value){
  setPopUp(value);
}
///////////////////////////////////////////////////////////////



/////////////////// All Item Return Request ///////////////////
function getAllRequests()
{
  let AUTH = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Authorization: `Bearer ${window.token}` },
  });
  
  AUTH.get("/ItemReturn/GetAllRequests").then(reply => {
  // console.log(reply.data);
  setitemData(reply.data.data);
  }).catch(err => {
    // console.log("err form get State: ",err);
    if (err.response.status == 401) {
      // console.log("Unauthorize");
    }
  });
}
///////////////////////////////////////////////////////////////



////////////////// Confirmed Return Request //////////////////
function getConfirmRequest()
{
  let AUTH = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Authorization: `Bearer ${window.token}` },
  });
  
  AUTH.get("/ItemReturn/GetAllApproveRequests").then(reply => {
  // console.log(reply.data);
  setConfirmRequest(reply.data.data);
  }).catch(err => {
    // console.log("err form get State: ",err);
    if (err.response.status == 401) {
      // console.log("Unauthorize");
    }
  });
}
///////////////////////////////////////////////////////////////



/////////////////// Rejected Return Request ///////////////////
function getRejectRequest()
{
  let AUTH = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { Authorization: `Bearer ${window.token}` },
  });
  
  AUTH.get("/ItemReturn/GetAllRejectRequests").then(reply => {
  // console.log(reply.data);
  setRejectRequest(reply.data.data);
  }).catch(err => {
    // console.log("err form get State: ",err);
    if (err.response.status == 401) {
      // console.log("Unauthorize");
    }
  });
}
///////////////////////////////////////////////////////////////



////////////////////// View More Button //////////////////////
function handleViewMore(item)
{
  setViewMore(item);
  setPopUp(true);
}
//////////////////////////////////////////////////////////////



useEffect(()=>{
  getAllRequests();
  getConfirmRequest();
  getRejectRequest();
},[])

return(
    <>
    {popUp && <ViewMore  item={viewmore} toClose={closePopUp}/>}
     <div className="row align-items-center justify-content-center  mt-5">
        {/* Row 1 */}
        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-9  justify-content-center mt-5 mb-5">
          <div className="row align-items-center justify-content-center ">
            {/* Card 1 Allrequests */}
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 align-items-center mt-2 justify-content-center">
              <div className="card custom-card" style={{ maxHeight:"31rem", padding: "10px",backgroundColor:'#bbffff' }}>
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
                <label>Return Requests</label>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <input 
                    type="search"
                    placeholder="Search Order..."
                    className="form-control form-control-sm"
                 />
                </div>
                </div>
                <hr/>
                <div style={{overflow:"auto"}}>
                <table className="table table-striped table-bordered">
                  <thead>
                  <tr>
                  <th style={{backgroundColor:'#ccddff'}}>Batch Number</th>
                  <th style={{backgroundColor:'#ccddff'}}> Item Code </th>
                  <th style={{backgroundColor:'#ccddff'}}>Item Name</th>
                  <th style={{backgroundColor:'#ccddff'}}>Requested Date</th>
                  <th style={{backgroundColor:'#ccddff'}}>Amount</th>
                  <th style={{backgroundColor:'#ccddff'}}></th>
                  </tr>
                  </thead>
                  <tbody>

                  {itemdata.map((e,i)=>(
                  <tr>
                    <td> {e.batchNo} </td>
                    <td> {e.productCode} </td>
                    <td> {e.productName} </td>
                    <td> {e.requestDate} </td>
                    <td> ₹{e.creditAmt} </td>
                    <td><button className="custom-button" onClick={()=>handleViewMore(e)}>View More</button></td>
                  </tr>
                  ))} 
                  </tbody>
                </table>
                </div>
              </div>
            </div>

            {/* Card 2 Confirm Requests*/}
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2 align-items-center  justify-content-center mt-5">
              <div className="card custom-card" style={{ height:"30rem", padding: "10px",backgroundColor:'#ffbbcc' }}>
              <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
                  <label>Confirm Requests </label>
                  </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <input 
                    type="search"
                    placeholder="Search Order..."
                    className="form-control form-control-sm"
                  />
                </div>
                </div>
                <hr/>
                <div style={{overflow:"auto"}}> 
                <table className="table table-bordered table-striped">
                  <thead>
                  <tr>
                  <th style={{backgroundColor:'#ffaaaa'}}>Item Code</th>
                  <th style={{backgroundColor:'#ffaaaa'}}>Item Name</th>
                  <th style={{backgroundColor:'#ffaaaa'}}>Approval Date</th>
                  <th style={{backgroundColor:'#ffaaaa'}}>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {confirmrequest.map((e,i)=>(
                  <tr>
                    <td> {e.productCode} </td>
                    <td> {e.productName} </td>
                    <td> {e.approvalDate} </td>
                    <td> ₹{e.creditAmt} </td>
                  </tr>
                  ))} 
                  </tbody>
                </table>
                </div>
              </div>
            </div>

            {/* card 3 Rejected Requests */}
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2 align-items-center  justify-content-center mt-5">
              <div className="card custom-card" style={{ height:"30rem", padding: "10px",backgroundColor:'#ffffaa'  }}>
              <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
                  <label>Rejected Requests </label>
                  </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <input 
                    type="search"
                    placeholder="Search Order..."
                    className="form-control form-control-sm"
                  />
                </div>
                </div>
                <hr/>
                <div style={{overflow:"auto"}}> 
                <table className="table table-bordered table-striped">
                  <thead>
                  <tr>
                  <th style={{backgroundColor:'#ffff66'}}>Item Code</th>
                  <th style={{backgroundColor:'#ffff66'}}>Item Name</th>
                  <th style={{backgroundColor:'#ffff66'}}>Rejected Date</th>
                  <th style={{backgroundColor:'#ffff66'}}>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {rejectrequest.map((e,i)=>(
                  <tr>
                    <td> {e.productCode} </td>
                    <td> {e.productName} </td>
                    <td> {e.approvalDate} </td>
                    <td> ₹{e.creditAmt} </td>
                  </tr>
                  ))} 
                  </tbody>
                </table>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
)
}
export default App_Del_ReturnItem; 