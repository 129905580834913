import axios from "axios";
import { useState } from "react";
import API_AUTH from "../../api_auth";

function ViewDealerTransactions(){

    let [transactions, setTransactions] = useState([]);

    let [formData, setFormData] = useState({
        startDate:"",
        endDate:""
    });

    let [errors, setErrors] = useState({
        startDate:"",
        endDate:""
    });

    function handleChange(e){

        let {name, value} = e.target;

        setFormData({
            ...formData,
            [name]: value
        });

        setErrors({
            ...errors,
            [name]:""
        });
    }

    function validate(){
        let newErrors = {};
        let errorMessage = [];

        if(!formData.startDate){
            newErrors.startDate = "Start Date Required!";
            errorMessage.push(newErrors.startDate);
        }

        if(!formData.endDate){
            newErrors.endDate = "End Date Required!";
            errorMessage.push(newErrors.startDate);
        }

        if(errorMessage > 0){
            alert(errorMessage.join("\n"));
        }

        return Object.keys(newErrors).length === 0;
    }
    function handleSearch(){
        
        if(validate()){

            API_AUTH().get(`/AmountTransactions/GetDealerTransactions/${window.uName}/${formData.startDate}/${formData.endDate}`).then(reply=>{
                if(reply.status == 200){
                    if(reply.data.status == "OK"){
                        if(reply.data.data){
                            setTransactions(reply.data.data);
                        }
                        else{
                            alert("NOT FOUND!");
                        }
                    }
                }
            })

        }
    }

    return(
        <>
        <div className="container-fluid">
        <div className="row" style={{marginTop:"100px"}}>
            
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <input type="date" className="form-control form-control-sm"
                value={formData.startDate}
                name="startDate"
                onChange={handleChange} />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6">
            <input type="date" className="form-control form-control-sm"
            value={formData.endDate}
            name="endDate"
            onChange={handleChange} />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                <button className="btn btn-primary btn-sm" onClick={handleSearch}>Search</button>
                </div>
        </div>
        
        <hr/>
        <table className="table table-bordered table-striped">
        <thead>
            <th>Date</th>
            <th>Transaction Type</th>
            <th>voucher No</th>
            <th>Amount</th>
            <th>Remarks</th>
               </thead>
           <tbody>
            {transactions.map((e, i)=>(
                <tr><td>{e.tDate}</td><td>{e.tType}</td><td>{e.voucherNo}</td><td>{e.amount}</td><td>{e.remarks}</td></tr>
            ))}
           </tbody>
        </table>
        </div>
    </>
    )
}
 export default ViewDealerTransactions;