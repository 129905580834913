import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import React, { useState } from "react";
import AdminHeader from "./Admin/adminHeader";

import {
  CNavbar,
  CContainer,
  CNavbarBrand
} from "@coreui/react";
import { connect } from "react-redux";
import DealerHeader from "./Dealer/dealerHeader";

function Header({commonData}) {
  const [isOpen, setIsOpen] = useState(false);
  let nav = useNavigate();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    {commonData.isLogin == "Y" ? (
      
      <CNavbar
        expand="md"
        colorScheme="light"
        className="custom-navbar"
      >
        <CContainer fluid>
          <CNavbarBrand>
            <img src={logo} style={{ width: "100px", cursor:"pointer" }} onClick={()=>{window.utype == 'Admin'? nav("/adminDashboard") : nav("/dealerDashboard")}}/>
          </CNavbarBrand>

            {window.utype == "Admin" ? (<AdminHeader/>) : (<>
            {window.utype == "Dealer" || "Staff" ? (<DealerHeader/>):(<></>)}
            </>)}

          
        </CContainer>

      </CNavbar> ) : (<></>)}
    </>
  );
}

let connectToStore=(state)=>({commonData: state});
export default connect(connectToStore) (Header);
